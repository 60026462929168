import React, { forwardRef } from 'react';
import { SwitchWrapper, Wrapper, Slider, Input, Label } from './Switch.style';

const Switch = (
  { label, containerStyle, switchStyle, labelStyle, disabled, ...rest },
  ref
) => {
  return (
    <Wrapper style={containerStyle} disabled={disabled}>
      {label && (
        <Label style={labelStyle} htmlFor={rest.name}>
          {label}
        </Label>
      )}
      <SwitchWrapper style={switchStyle}>
        <Input ref={ref} type='checkbox' disabled={disabled} {...rest} />
        <Slider />
      </SwitchWrapper>
    </Wrapper>
  );
};

export default forwardRef(Switch);
