import React from 'react';

export default function OdsIconExtension({ svg, path, ...rest }) {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...svg}
      {...rest}
    >
      <path
        d='M7 17.5C4.51875 17.5 2.5 19.7425 2.5 22.5C2.5 25.2575 4.51875 27.5 7 27.5C9.48125 27.5 11.5 25.2575 11.5 22.5C11.5 19.7425 9.48125 17.5 7 17.5ZM7 24.5C6.1875 24.5 5.5 23.5838 5.5 22.5C5.5 21.4162 6.1875 20.5 7 20.5C7.8125 20.5 8.5 21.4162 8.5 22.5C8.5 23.5838 7.8125 24.5 7 24.5ZM6 15C6.39782 15 6.77936 14.842 7.06066 14.5607C7.34196 14.2794 7.5 13.8978 7.5 13.5V5.5H17V11.5C17 11.8978 17.158 12.2794 17.4393 12.5607C17.7206 12.842 18.1022 13 18.5 13H24.5V13.5C24.5 13.8978 24.658 14.2794 24.9393 14.5607C25.2206 14.842 25.6022 15 26 15C26.3978 15 26.7794 14.842 27.0607 14.5607C27.342 14.2794 27.5 13.8978 27.5 13.5V11C27.5 10.6025 27.3422 10.2212 27.0613 9.94L20.0613 2.94C19.9219 2.80052 19.7565 2.68987 19.5744 2.61437C19.3923 2.53887 19.1971 2.50001 19 2.5H7C6.33696 2.5 5.70107 2.76339 5.23223 3.23223C4.76339 3.70107 4.5 4.33696 4.5 5V13.5C4.5 13.8978 4.65804 14.2794 4.93934 14.5607C5.22064 14.842 5.60218 15 6 15ZM20 7.125L22.875 10H20V7.125Z'
        fill='#323232'
        {...path}
      />
      <path
        d='M24.8191 20.8684C25.4571 21.0526 26.1801 21.2615 26.7772 21.6639H26.7749C27.6873 22.2776 28.1155 23.211 27.9732 24.2984C27.928 24.721 27.7901 25.1284 27.5692 25.4914C27.3484 25.8545 27.05 26.1643 26.6956 26.3986C25.9955 26.863 25.1473 26.9995 24.3935 26.9995C23.7547 26.9959 23.119 26.91 22.5022 26.744C22.3221 26.6972 22.1532 26.6151 22.0052 26.5022C21.8573 26.3894 21.7333 26.2483 21.6405 26.087C21.5477 25.9258 21.488 25.7476 21.4649 25.563C21.4418 25.3784 21.4556 25.1911 21.5057 25.0119C21.5558 24.8327 21.6411 24.6653 21.7566 24.5195C21.8721 24.3737 22.0155 24.2523 22.1785 24.1625C22.3414 24.0727 22.5206 24.0163 22.7056 23.9966C22.8906 23.9769 23.0777 23.9942 23.2559 24.0476C24.0505 24.2634 25.0084 24.2354 25.1683 24.0546C25.1805 24.0284 25.1888 24.0004 25.1928 23.9717C24.9664 23.8282 24.39 23.6637 24.04 23.5599L24.0345 23.5583C23.4073 23.3768 22.7584 23.189 22.2023 22.8295C21.3132 22.2554 20.8944 21.3768 21.0227 20.3571C21.0661 19.9414 21.2003 19.5403 21.4159 19.1822C21.6314 18.8242 21.923 18.5178 22.27 18.2849C22.9817 17.8077 23.9233 17.6046 25.0819 17.6816C25.5541 17.7127 26.0226 17.7853 26.4821 17.8987C26.828 18.0045 27.1196 18.2402 27.2956 18.5563C27.4716 18.8724 27.5184 19.2444 27.4263 19.5942C27.3341 19.9441 27.11 20.2447 26.8011 20.433C26.4922 20.6213 26.1224 20.6828 25.7692 20.6044C25.0271 20.4142 24.18 20.4282 23.8941 20.5659C24.1967 20.6827 24.5051 20.7836 24.8182 20.8681L24.8191 20.8684Z'
        fill='#323232'
        {...path}
      />
      <path
        d='M17.7941 25.2009L17.7941 25.2009C17.3784 25.3978 16.9241 25.5 16.464 25.5H13.5V19.5H17.0385C17.5931 19.5 18.1267 19.7126 18.5294 20.0941C18.7012 20.2569 18.8452 20.4467 18.9557 20.656L18.9557 20.656L19.073 20.8784C19.3534 21.4096 19.5 22.0013 19.5 22.6021V22.7894C19.5 22.9653 19.4817 23.1407 19.4454 23.3128C19.2819 24.0871 18.7661 24.7405 18.0509 25.0793L17.7941 25.2009Z'
        stroke='#323232'
        strokeWidth='3'
        {...path}
      />
    </svg>
  );
}
