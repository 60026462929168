import React from 'react';

export default function Kebab({ svg, rect, ...rest }) {
  return (
    <svg
      enableBackground='new 0 0 24 24'
      viewBox='0 0 24 24'
      id='kebab-menu'
      {...svg}
      {...rest}
    >
      <circle cx='12' cy='5' r='2'></circle>
      <circle cx='12' cy='19' r='2'></circle>
      <circle cx='12' cy='12' r='2'></circle>
    </svg>
  );
}
