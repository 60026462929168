import React from 'react';

export default function DownloadIcon({ svg, path, ...rest }) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...svg}
      {...rest}
    >
      <path
        d='M21.375 14.25V19.5C21.375 19.9973 21.1775 20.4742 20.8258 20.8258C20.4742 21.1775 19.9973 21.375 19.5 21.375H4.5C4.00272 21.375 3.52581 21.1775 3.17417 20.8258C2.82254 20.4742 2.625 19.9973 2.625 19.5V14.25C2.625 13.9516 2.74353 13.6655 2.9545 13.4545C3.16548 13.2435 3.45163 13.125 3.75 13.125C4.04837 13.125 4.33452 13.2435 4.5455 13.4545C4.75647 13.6655 4.875 13.9516 4.875 14.25V19.125H19.125V14.25C19.125 13.9516 19.2435 13.6655 19.4545 13.4545C19.6655 13.2435 19.9516 13.125 20.25 13.125C20.5484 13.125 20.8345 13.2435 21.0455 13.4545C21.2565 13.6655 21.375 13.9516 21.375 14.25ZM11.2041 15.0459C11.3086 15.1508 11.4328 15.234 11.5695 15.2908C11.7063 15.3476 11.8529 15.3768 12.0009 15.3768C12.149 15.3768 12.2956 15.3476 12.4324 15.2908C12.5691 15.234 12.6933 15.1508 12.7978 15.0459L16.5478 11.2959C16.7592 11.0846 16.8779 10.7979 16.8779 10.4991C16.8779 10.2002 16.7592 9.91353 16.5478 9.70219C16.3365 9.49084 16.0498 9.37211 15.7509 9.37211C15.4521 9.37211 15.1654 9.49084 14.9541 9.70219L13.125 11.5312V3.75C13.125 3.45163 13.0065 3.16548 12.7955 2.9545C12.5845 2.74353 12.2984 2.625 12 2.625C11.7016 2.625 11.4155 2.74353 11.2045 2.9545C10.9935 3.16548 10.875 3.45163 10.875 3.75V11.5312L9.04594 9.70406C8.83459 9.49272 8.54795 9.37399 8.24906 9.37399C7.95018 9.37399 7.66353 9.49272 7.45219 9.70406C7.24084 9.91541 7.12211 10.2021 7.12211 10.5009C7.12211 10.7998 7.24084 11.0865 7.45219 11.2978L11.2041 15.0459Z'
        fill='#666666'
        {...path}
      />
    </svg>
  );
}
