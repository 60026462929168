/* eslint-disable no-unused-vars */
import React, { forwardRef, useLayoutEffect, useRef, useState } from 'react';
import { ChevronWrapper, Wrapper } from './styles';
import Input from './components/Input';
import Menu from './components/Menu';
import { getIcon } from '../Icon/getIcon';
import { itemsLimit } from './utils/itemsLimit';

const Chevron = getIcon('chevron');

function AutoComplete(
  {
    name,
    inputStyle,
    containerStyle,
    menuStyle,
    defaultValue,
    renderMenuItem,
    startSearch,
    createOptions,
    delay,
    disabled,
    onChange,
    filter,
    oneTimeRequest,
    errorConfig = {},
    inputProps = {},
    extractKey,
    comparison,
    loaders = {},
    options = {
      freezeOnMount: false,
      fixedItems: [],
      searchDeps: []
    },
    afterSearch = () => {},
    helper = () => {}
  },
  ref
) {
  if (!oneTimeRequest) {
    throw new Error('Ainda não modificamos o uso para sem o `oneTimeRequest`');
  }

  const [state, setState] = useState({
    data: [],
    selected: null,
    options: [],
    errors: [],
    loading: false
  });

  const _ref = useRef();
  let inputRef = ref && Object.keys(ref)[0] === 'current' ? ref : _ref;

  const menuRef = useRef();
  const wrapperRef = useRef();
  const chevronRef = useRef();
  const nativeSetter = useRef();

  const control = useRef({
    useChevron: false,
    inputIsFocused: false
  });

  const loading = useRef(false);
  const cacheData = useRef({
    allData: [],
    filteredData: [],
    runOnFocus: false
  });
  let timer = null;

  const onChangeData = (data, cache) => {
    const _options = data.map(value => createOptions(value));

    loading.current = false;

    if (cache) {
      saveCache(cache);
    }

    if (
      [...options.fixedItems, ...cacheData.current.filteredData].length !==
      state.data.length
    ) {
      setState({
        data: itemsLimit([...options.fixedItems, ...data]),
        options: [...options.fixedItems, ..._options],
        selected: state.selected,
        errors: [],
        loading: false
      });
    }
  };

  const runCache = type => {
    if (type === 'allData') {
      const data = cacheData.current.allData;
      onChangeData(data, { allData: data, filteredData: data });
      return;
    }
  };

  const saveCache = ({ allData, filteredData }, options) => {
    if (options) {
      const { runOnFocus } = options;
      cacheData.current = {
        allData,
        filteredData,
        runOnFocus
      };
    } else {
      cacheData.current = {
        allData,
        filteredData,
        runOnFocus: false
      };
    }
  };

  const runRequest = async event => {
    const { value } = event.target;

    const allData = await startSearch();

    const filteredData = allData.filter(item => filter(item, value));
    afterSearch(filteredData, value);

    if (filteredData.length === 0) throw new Error('Nenhum resultado.');

    onChangeData(filteredData, { allData, filteredData });
  };

  const debounce = event => {
    if (menuRef.current.dataset.hide === 'true') {
      menuRef.current.dataset.hide = 'false';
    }

    if (!loading.current) {
      loading.current = true;

      setState(current => ({ ...current, loading: true }));
    }

    if (timer) {
      clearTimeout(timer);
    }

    if (event.target.value === '' && cacheData.current.allData.length > 0) {
      runCache('allData');
      return;
    }

    timer = setTimeout(async () => {
      try {
        await runRequest(event);
      } catch (e) {
        loading.current = false;
        setState({
          data: [],
          options: [],
          errors: [e.message],
          loading: false
        });
      }
    }, delay ?? 700);
  };

  const noDebounce = async event => {
    const { value } = event.target;

    if (menuRef.current.dataset.hide === 'true') {
      menuRef.current.dataset.hide = 'false';
    }

    try {
      const result = cacheData.current.allData.filter(item =>
        filter(item, value)
      );

      if (result.length === 0) throw new Error('Nenhum resultado.');

      onChangeData(result, {
        allData: cacheData.current.allData,
        filteredData: result
      });
    } catch (e) {
      if (state.errors[0] !== e.message) {
        setState({
          data: [],
          options: [],
          selected: state.selected,
          errors: [e.message],
          loading: false
        });
      }
    }
  };

  const handleSelect = index => {
    const SELECTED_INDEX_FROM_ALL_ITEMS = cacheData.current.allData.indexOf(
      state.data[index]
    );

    const data = itemsLimit(
      [...options.fixedItems, ...cacheData.current.allData],
      SELECTED_INDEX_FROM_ALL_ITEMS
    );

    nativeSetter.current(
      updateInputValue(null, state.options[index].label, false)
    );
    inputRef.current.blur();
    menuRef.current.dataset.hide = 'true';

    if (onChange instanceof Function) {
      onChange(state.options[index].value, state.options[index]);
    }

    saveCache({
      allData: cacheData.current.allData,
      filteredData: data
    });

    setState({
      errors: [],
      loading: false,
      selected: state.options[index].value,
      options: [...data.map(value => createOptions(value))],
      data
    });
  };

  const handleAdd = async () => {
    const value = inputRef.current.value;

    if (errorConfig.addItem) {
      const { addItem } = errorConfig;

      const _data = itemsLimit(cacheData.current.allData);

      const { data } = await addItem(value);
      const _options = [..._data, data].map(value => createOptions(value));

      setState({
        selected: state.selected,
        data: [...options.fixedItems, ..._data, data],
        options: [...options.fixedItems, ..._options],
        errors: [],
        loading: false
      });
      saveCache({
        allData: [...cacheData.current.allData, data],
        filteredData: [...cacheData.current.allData, data]
      });

      // if (onChange instanceof Function) {
      //   onChange(_options.value, _options);
      // }

      return options.value;
    }

    return '';
  };

  const hideMenu = () => {
    // Menu is always open when input is focused
    control.current.inputIsFocused = false;

    chevronRef.current.dataset.up = 'false';

    if (menuRef.current.dataset.hide === 'false') {
      menuRef.current.dataset.hide = 'true';
    }
  };

  const focusOut = () => {
    if (!control.current.useChevron) {
      hideMenu();
    }
  };

  const focusIn = () => {
    control.current.inputIsFocused = true;

    chevronRef.current.dataset.up = 'true';

    if (cacheData.current.runOnFocus) {
      const _options = cacheData.current.allData.map(value =>
        createOptions(value)
      );
      const mergedOptions = [...options.fixedItems, ..._options];

      let selectedItem;

      if (inputRef.current.value) {
        selectedItem = mergedOptions.find(
          options => options.label === inputRef.current.value
        )?.value;
      }

      cacheData.current.runOnFocus = false;

      setState({
        selected: selectedItem,
        data: itemsLimit([...options.fixedItems, ...cacheData.current.allData]),
        options: mergedOptions,
        errors: [],
        loading: false
      });
    }
    if (menuRef.current.dataset.hide === 'true') {
      menuRef.current.dataset.hide = 'false';
    }
  };

  const updateInputValue = (items, inputValue, needFiltering) => {
    if (!needFiltering) {
      return inputValue;
    }

    const _options = items.map(value => createOptions(value));
    const mergedOptions = [...options.fixedItems, ..._options];

    const findByValue = option => {
      if (comparison) {
        return comparison(option, inputValue);
      }
      return String(option.value) === String(inputValue);
    };

    const findByLabel = option => {
      if (comparison) {
        return comparison(option, inputValue);
      }
      return String(option.label) === String(inputValue);
    };

    const itemResult =
      mergedOptions.find(findByValue) ?? mergedOptions.find(findByLabel);

    const filteredData = items.filter(item =>
      filter(item, itemResult?.label ?? '')
    );

    saveCache(
      { allData: items, filteredData: filteredData },
      { runOnFocus: true }
    );

    inputRef.current.blur();
    return itemResult?.label ?? '';
  };

  const handleMenuOnInputClick = e => {
    const inputValue = e.target.value;

    if (inputValue === '' && menuRef.current.dataset.hide === 'false') {
      inputRef.current.blur();
    } else if (menuRef.current.dataset.hide === 'true') {
      focusIn();
    }
  };

  const handleMenuOnChevronClick = () => {
    if (control.current.useChevron) {
      inputRef.current.focus();
      focusIn();
    } else {
      hideMenu();
    }
  };

  const getInitialValue = async () => {
    setState(curr => ({ ...curr, loading: true }));
    return await startSearch();
  };

  const handleInitialValue = value => {
    if (oneTimeRequest && !value) {
      getInitialValue()
        .then(items => {
          saveCache(
            { allData: items, filteredData: items },
            { runOnFocus: true }
          );
          afterSearch(items);
        })
        .finally(() => {
          setState(curr => ({ ...curr, loading: false }));
        });
    }

    if (value) {
      getInitialValue()
        .then(items => {
          afterSearch(items, value);
          nativeSetter.current(updateInputValue(items, value, true));
        })
        .finally(() => {
          setState(curr => ({ ...curr, loading: false }));
        });
    }
  };

  useLayoutEffect(() => {
    helper({
      ref: inputRef.current
    });

    inputRef.current.addEventListener('focusout', focusOut);
    // inputRef.current.addEventListener('focusin', focusIn);

    const nativeValueSetter = Object.getOwnPropertyDescriptor(
      window.HTMLInputElement.prototype,
      'value'
    ).set;

    nativeSetter.current = nativeValueSetter.bind(inputRef.current);

    Object.defineProperty(inputRef.current, 'value', {
      set: function (newValue) {
        nativeValueSetter.call(
          this,
          updateInputValue(cacheData.current.allData, newValue, true)
        );
      }
    });

    return () => {
      inputRef.current.removeEventListener('focusout', focusOut);
      // inputRef.current.removeEventListener('focusin', focusIn);
    };
  }, []);

  useLayoutEffect(() => {
    if (oneTimeRequest) {
      inputRef.current.addEventListener('input', noDebounce);
    }

    return () => {
      if (oneTimeRequest) {
        inputRef.current.removeEventListener('input', noDebounce);
      }
    };
  }, [state.data]);

  useLayoutEffect(() => {
    if (!oneTimeRequest) {
      inputRef.current.addEventListener('input', debounce);
    }
    return () => {
      if (!oneTimeRequest) {
        inputRef.current.removeEventListener('input', debounce);
      }
    };
  }, []);

  const handleClickOutside = event => {
    if (event.target.id === 'chevron') {
      if (menuRef.current.dataset.hide === 'true') {
        control.current.useChevron = true;
      } else {
        control.current.useChevron = false;
      }
    } else {
      control.current.useChevron = false;
    }
  };

  useLayoutEffect(() => {
    wrapperRef.current.addEventListener('mousedown', handleClickOutside);
    return () => {
      wrapperRef.current.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  if (errorConfig.addItem) {
    errorConfig = { ...errorConfig, handleAdd };
  }

  return (
    <>
      {loaders.wrapper &&
        state.loading &&
        !options.freezeOnMount &&
        loaders.wrapper}
      <Wrapper
        ref={wrapperRef}
        hideAndShowLoader={
          loaders.wrapper && !options.freezeOnMount && state.loading
        }
        {...(containerStyle && { style: containerStyle })}
      >
        <Input
          ref={inputRef}
          {...inputProps}
          disabled={disabled}
          name={name}
          defaultValue={defaultValue}
          error={errorConfig.message}
          style={{ ...inputStyle, ...inputProps.style }}
          handleInitialValue={handleInitialValue}
          onClick={handleMenuOnInputClick}
          deps={options.searchDeps ? [...options.searchDeps] : []}
          freezeOnMount={options.freezeOnMount}
          Chevron={
            <ChevronWrapper
              id='chevron'
              type='button'
              ref={chevronRef}
              onClick={handleMenuOnChevronClick}
            >
              <Chevron />
            </ChevronWrapper>
          }
          autoComplete='off'
        />

        <Menu
          ref={menuRef}
          inputRef={inputRef}
          menuStyle={menuStyle}
          errorConfig={errorConfig}
          stateConfig={{ state }}
          fixedItems={options.fixedItems}
          renderItem={renderMenuItem}
          extractKey={extractKey}
          handleSelect={handleSelect}
          loader={loaders.menu}
        />
      </Wrapper>
    </>
  );
}

export default forwardRef(AutoComplete);
