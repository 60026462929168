import styled from 'styled-components'

export const ErrorHeaderTitle = styled.h1`
  margin: 0;
  padding: 0;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  align-self: flex-start;
`
export const ErrorsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  `

export const ErrorsTitle = styled.h1`
    margin:0;
    padding:0;
`

export const ErrorsList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-left: 30px;
`

export const ErrorListItem = styled.li`
    list-style-type: disc;
`

export const ErrorFooter = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    
`