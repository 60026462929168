import React from 'react';
import PropTypes from 'prop-types';
import * as Inputs from './components/Inputs.index';

export default function Input({ type, ...props }) {
  const Input = Inputs[type];

  return <Input {...props} />;
}

Input.propTypes = {
  type: PropTypes.string.isRequired
};
