import React, { useState } from 'react';

export let handleOptionsView;
export let optionsViewlist;
export let userDetails;

const useSidebarConfig = ({ config, user }) => {
  const [optionsView, setOptionsView] = useState(<></>);

  handleOptionsView = setOptionsView;
  userDetails = user;

  if (config === undefined) {
    throw new Error(
      'Você provavelmente esqueceu de passar a prop "config" para Sidebar'
    );
  }
  if (user === undefined) {
    throw new Error(
      'Você provavelmente esqueceu de passar a prop "user" para Sidebar'
    );
  }

  const configMapped = new Map(Object.entries(config));

  optionsViewlist = configMapped;

  return { optionsView };
};

export default useSidebarConfig;
