import { useState } from 'react';

export let handleOpen;

const useOptionViewVisibility = () => {
  const [open, setOpen] = useState(false);
  handleOpen = setOpen;

  return { open, setOpen };
};

export default useOptionViewVisibility;
