import styled from 'styled-components';

export const ListContentWrapper = styled.ul`
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: relative;
    background-color: hsl(0, 0%, 100%);
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 0px 0px 8px 8px;

    & > li {
        display: grid;
        grid-auto-flow: column;
        grid-auto-columns: minmax(100px, 1fr);
        border-bottom: 1px solid hsl(220, 17%, 93%);
        align-items: center;
        padding-left: 15px;
        
        & > div {
            display: flex;
            align-items: center;
            gap: 5px;
        }
    }

    &::-webkit-scrollbar {
        width:1.2em;
    }
    &::-webkit-scrollbar-track {
        background-color: hsl(220,17%,93%);
    }
    &::-webkit-scrollbar-thumb {
        background-color: hsl(0,0%,76%);
        border: 0.3em solid hsl(220,17%,93%);
    }
    &::-webkit-scrollbar-thumb:hover {
        background-color: hsl(0,0%,66%);
    }
`


export const ListContentRow = styled.li`
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    & > * {
        word-wrap: break-word;
        flex: 1;
    }
    & > div {
        padding: 15px 0;
        & > img {
            width:32px;
            height:32px;
            border-radius: 50%;
        }
    }
`

export const ListContentHeaders = styled.li`
    position: sticky;
    background-color: hsl(0, 0%, 100%);
    top:0;
    z-index: 1;
    
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;

    > div {
        padding: 8px 0;
    }

`