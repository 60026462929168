import React, { forwardRef, useRef, useState } from 'react';
import { Container } from './DraggableArea.style';

// <DraggableArea>
//   <div>
//     Drop here
//   </div>
// </DraggableArea>

{
  /* <DraggableArea>
  {({isOver, dropped}) => (
    <>
    <div>
    <input
        ref={inputRef}
        type='file'
        accept={
          formats ? formats.map(format => `.${format}`).join(', ') : undefined
        }
        multiple={false}
        onChange={handleUpload}
      />
    </div>
    </>
  )}
</DraggableArea> */
}

function DraggableArea({ children, onEnter, onUpload, onLeave, style }, ref) {
  let refHook = useRef();
  let _ref = ref && Object.keys(ref)[0] === 'current' ? ref : refHook;

  const [draggableState, setDraggableState] = useState({
    dropped: false,
    isOver: false
  });

  const handleDragOver = e => {
    e.preventDefault();
  };

  const handleDragEnter = e => {
    e.preventDefault();
    setDraggableState(current => ({ ...current, isOver: true }));
    onEnter instanceof Function && onEnter(e);
  };

  const handleDragLeave = e => {
    e.preventDefault();
    setDraggableState(current => ({ ...current, isOver: false }));
    onEnter instanceof Function && onLeave(e);
  };

  const handleDrop = e => {
    e.preventDefault();
    setDraggableState(current => ({
      ...current,
      isOver: false,
      dropped: true
    }));
    const files = e.dataTransfer ? [...e.dataTransfer.files] : [];
    onUpload instanceof Function && onUpload(files);
  };

  return (
    <Container
      ref={_ref}
      style={style}
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      {typeof children === 'function' ? children(draggableState) : children}
    </Container>
  );
}

export default forwardRef(DraggableArea);
