function maskCpf(value) {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .substring(0, 14);
}
function maskCnpj(value) {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .substring(0, 18);
}

export const masks = {
  'cpf/cnpj': value => {
    const length = value.replace(/\D/g, '').length;
    return length <= 11 ? maskCpf(value) : maskCnpj(value);
  },
  cpf: maskCpf,
  cnpj: maskCnpj,
  phone: value => {
    const cleanValue = value.replace(/\D/g, '');

    let maskedValue = cleanValue;
    // Telefone Fixo
    if (cleanValue.length < 11) {
      maskedValue = maskedValue.replace(/^(\d{1})$/g, '($&');
      maskedValue = maskedValue.replace(/^(\d{2})$/g, '($&');
      maskedValue = maskedValue.replace(/^(\d{2})(\d)/g, '($1) $2');
      maskedValue = maskedValue.replace(/(\d{4})(\d{1,4})$/, '$1-$2');
    }
    // Celular
    else {
      maskedValue = maskedValue.slice(0, 11);
      maskedValue = maskedValue.replace(
        /(\d{2})(\d{1})(\d{4})(\d{4})/,
        '($1) $2 $3-$4'
      );
    }

    return maskedValue;
  },
  date: value => {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1/$2')
      .replace(/(\d{2})(\d)/, '$1/$2')
      .substring(0, 10);
  }
};
