import { css } from 'styled-components';

const height = '45px';

const extraSmall = '100px';
const small = '170px';
const medium = '200px';
const large = '400px';
const extraLarge = '600px';
const full = '100%';
const auto = 'auto';
const viewport = '100vw';

const cssTemplate = style => css(style);

const styleSizes = {
  xs: cssTemplate({
    width: extraSmall,
    height
  }),
  sm: cssTemplate({
    width: small,
    height
  }),
  md: cssTemplate({
    width: medium,
    height
  }),
  lg: cssTemplate({
    width: large,
    height
  }),
  xl: cssTemplate({
    width: extraLarge,
    height
  }),
  auto: cssTemplate({
    width: auto,
    height: auto
  }),
  full: cssTemplate({
    width: full,
    height
  }),
  viewport: cssTemplate({
    width: viewport,
    height
  }),
  default: cssTemplate({
    width: 'auto',
    height: 'auto'
  }),
  ['auto-w']: cssTemplate({
    width: auto,
    height
  })
};

export const SIZES = {
  xs: styleSizes.xs,
  sm: styleSizes.sm,
  md: styleSizes.md,
  lg: styleSizes.lg,
  xl: styleSizes.xl,
  auto: styleSizes.auto,
  full: styleSizes.full,
  viewport: styleSizes.viewport,
  ['auto-w']: styleSizes['auto-w'],
  default: styleSizes.default
};
