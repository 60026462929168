import styled, { css } from 'styled-components';

export const InputCheckboxWrapper = styled.label`
  display: flex;
  align-items: center;

  ${function ({ error }) {
    if (error) {
      return css`
        input[type='checkbox'] + .checkmark {
          border: 2px solid #ff4242;
        }
      `;
    }
  }}

  input[type='checkbox'] {
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    margin: 0;
    padding: 0;
  }

  .checkmark {
    position: relative;
    display: flex;
    width: 20px;
    height: 20px;
    background: white;
    border: 2px solid #d0d5dd;
    box-sizing: border-box;
    border-radius: 6px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    transition: box-shadow ease 350ms, border ease 260ms, background ease 300ms;

    ${function ({ error }) {
      if (error) {
        return css`
          &:hover {
            border: 2px solid #ff4242;
            box-shadow: 0px 0px 0px 2.5px rgb(255, 66, 66, 0.3);
          }
        `;
      }

      return css`
        &:hover {
          border: 2px solid rgba(0, 191, 111, 1);
          box-shadow: 0px 0px 0px 2.5px rgba(0, 191, 111, 0.3);
        }
      `;
    }}
  }

  .title {
    margin-left: 10px;
  }

  input[type='checkbox']:checked + .checkmark {
    background: #00bf6f;
    border: 2px solid rgba(0, 191, 111, 1);
  }
`;
