import React from 'react';
import { Container } from './Button.style';
import { SIZES } from './logic/sizes';

function getSize(size) {
  return SIZES[size || 'default'];
}

function Button({ size, noStyle, Icon, title, as, children, ...rest }) {
  if ((Icon || title) && children) {
    throw new Error(
      'Se você deseja usar o botão passando children, passe as props como um JSX (exceto "size") na children também'
    );
  }

  function checkIconProvided() {
    return typeof Icon === 'function' ? <Icon /> : Icon;
  }

  return (
    <Container as={as} size={getSize(size)} noStyle={noStyle} {...rest}>
      {Icon && checkIconProvided()}
      {title && <span>{title}</span>}
      {children && children}
    </Container>
  );
}

export default Button;
