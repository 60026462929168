import React, { useState, useRef } from 'react'

export default function useRenderChildrens ({childrens, ref}) {
    const childrensRef = useRef(childrens)
    const [renderToggler, setRenderToggler] = useState(false)

    function renderChildren (newChildrens) {
        childrensRef.current = newChildrens.map((child, index) => {
          return React.cloneElement(
            child,
            { key: index }
          )
        })
        setRenderToggler(!renderToggler)
    }

    ref.current = {render: renderChildren}
    return childrensRef
}