import { createIcon } from './createIcon';

export function getIcon(name, props = {}) {
  const iconSettings = createIcon();

  let handleIcon = null;

  handleIcon = iconSettings.generate({
    properties: { name, props }
  });

  const IconComponent = iconSettings.icon.parentJsx;

  return IconComponent;
}
