import React, { forwardRef, isValidElement } from 'react';
import {
  ErrorMessage,
  StyledInput,
  Label,
  Container,
  AbsoluteWrapper,
  InputWrapper
} from './Input.style';
import { mergeStyles } from '../../../styles/mergeStyles';

function Input(props, ref) {
  const {
    width,
    height,
    style,
    label,
    error,
    containerSize,
    containerStyle,
    disabled = false,
    Element,
    ElementWrapperStyle,
    ...rest
  } = props;

  const renderCustomElement = () => {
    if (Element) {
      return isValidElement(Element) ? (
        <AbsoluteWrapper style={ElementWrapperStyle}>{Element}</AbsoluteWrapper>
      ) : (
        <AbsoluteWrapper style={ElementWrapperStyle}>
          <Element />
        </AbsoluteWrapper>
      );
    }

    return null;
  };

  return (
    <Container disabled={disabled} size={containerSize} style={containerStyle}>
      {label && <Label htmlFor={rest.name}>{label}</Label>}
      <InputWrapper>
        <StyledInput
          ref={ref}
          style={mergeStyles({ width, height, ...style })}
          hasElement={Element}
          type='text'
          disabled={disabled}
          {...rest}
          error={error}
        />
        {renderCustomElement()}
      </InputWrapper>
      {error && error?.message && <ErrorMessage>{error.message}</ErrorMessage>}
    </Container>
  );
}

export default forwardRef(Input);
