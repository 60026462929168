import styled, { css, keyframes } from 'styled-components';

const show = keyframes`
  from {
      opacity: 0;
      transform: translateY(-100%);
    }
    to {
      opacity: 1;
      transform: ${props => `translateY(${props.offset})`};
    }
`;

const MainToastStyle = css`
  position: absolute;
  right: 100%;
  white-space: nowrap;
  padding: 14px 20px;
  gap: 15px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  border-radius: 5px;
  transition: transform 280ms cubic-bezier(0.25, 1, 0.5, 1),
    opacity 280ms cubic-bezier(0.25, 1, 0.5, 1);
  transform: ${props => `translateY(${props.offset})`};
  animation: ${show} 280ms cubic-bezier(0.25, 1, 0.5, 1);
`;

export const StyledToast = styled.div`
  ${MainToastStyle}
  background: ${({ type }) =>
    type === 'success'
      ? '#00A55A'
      : type === 'warning'
      ? '#ddc035'
      : '#ff4040'};
  ${({ containerStyle }) => containerStyle}
`;

export const CustomStyledToast = styled.div`
  ${MainToastStyle}
  ${({ containerStyle }) => containerStyle}
`;
