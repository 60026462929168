import styled, { css } from 'styled-components';

const optionsButtonCss = css`
  height: 32px;
  padding: 11px;
  justify-content: flex-start;
  border: none;
  white-space: nowrap;

  :hover {
    background: #f9fafb;
  }
`;

export const TableMainContainer = styled.div`
  position: relative;
`;

export const TableWrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: column;
  border-radius: 7px;
  font-family: 'Poppins', sans-serif;
  overflow: auto;

  * {
    box-sizing: border-box;
  }
`;

export const TableHeader = styled.div`
  display: grid;
  ${({ length }) => {
    return css`
      grid-template-columns: repeat(${length}, minmax(200px, 1fr));
    `;
  }}
  grid-template-rows: 55px;
  background: #ffffff;
  /* border: 1px solid #323232; */
  position: sticky;
  top: 0;
  z-index: 2;
`;

export const TableHeaderItem = styled.div`
  font-weight: 500;
  padding: 14px 10px;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;

  :nth-child(1) {
    border-top: 1px solid rgb(234, 236, 240);
    border-bottom: 1px solid rgb(234, 236, 240);
    border-left: 1px solid rgb(234, 236, 240);
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
  }

  :last-child {
    border-top: 1px solid rgb(234, 236, 240);
    border-bottom: 1px solid rgb(234, 236, 240);
    border-right: 1px solid rgb(234, 236, 240);
    border-top-right-radius: 7px;
    /* border-bottom-right-radius: 7px; */
  }

  & ~ & {
    border-top: 1px solid rgb(234, 236, 240);
    border-bottom: 1px solid rgb(234, 236, 240);
    /* box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -2px rgba(0, 0, 0, 0.1); */
  }
`;

export const TableHeaderText = styled.span`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`;

export const TableBodyStyled = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const TableRow = styled.div`
  display: grid;
  ${({ length }) => {
    return css`
      grid-template-columns: repeat(${length}, minmax(200px, 1fr));
    `;
  }}

  grid-template-rows: 65px;

  font-size: 14px;
  position: relative;

  & ~ & > :nth-child(1n) {
    border-top: 1px solid rgb(234, 236, 240);
  }
  &:nth-child(even) > :nth-child(1n) {
    background: rgb(249, 250, 251);
  }
`;

export const TableCell = styled.div`
  padding: ${({ isOptions }) => (isOptions ? '14px 0' : '14px 10px')};
  color: #323232;
  display: flex;
  align-items: center;
  ${({ isOptions }) => isOptions && 'justify-content: center'};
  position: relative;
`;

export const TextWrapper = styled.span`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`;

export const OptionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
  flex-wrap: nowrap;
  position: relative;
  height: 65px;
`;

export const OptionsFloating = styled.div`
  position: absolute;
  top: 55px;
  right: 0;
  z-index: 5;

  background: white;

  border-left: 1px solid rgb(234, 236, 240);
  border-right: 1px solid rgb(234, 236, 240);
  border-bottom: 1px solid rgb(234, 236, 240);

  /* box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -2px rgba(0, 0, 0, 0.1); */
`;

export const OptionsDropdown = styled.div`
  position: absolute;
  left: 10px;
  transform: translateX(-100%);
  top: 50%;
  display: flex;
  gap: 5px;
  z-index: 3;
  flex-direction: column;
  background: white;
  padding: 8px;
  border: 1px solid #e4e6e8;
  border-radius: 10px;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.09);

  button {
    ${optionsButtonCss}
  }
`;

export const OptionsButton = styled.button`
  ${optionsButtonCss}
`;
