import React from 'react';

export default function OfxIconExtension({ svg, path, ...rest }) {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      {...svg}
      {...rest}
    >
      <path
        d='M7 17.5C4.51875 17.5 2.5 19.7425 2.5 22.5C2.5 25.2575 4.51875 27.5 7 27.5C9.48125 27.5 11.5 25.2575 11.5 22.5C11.5 19.7425 9.48125 17.5 7 17.5ZM7 24.5C6.1875 24.5 5.5 23.5837 5.5 22.5C5.5 21.4163 6.1875 20.5 7 20.5C7.8125 20.5 8.5 21.4163 8.5 22.5C8.5 23.5837 7.8125 24.5 7 24.5ZM6 15C6.39782 15 6.77936 14.842 7.06066 14.5607C7.34196 14.2794 7.5 13.8978 7.5 13.5V5.5H17V11.5C17 11.8978 17.158 12.2794 17.4393 12.5607C17.7206 12.842 18.1022 13 18.5 13H24.5V13.5C24.5 13.8978 24.658 14.2794 24.9393 14.5607C25.2206 14.842 25.6022 15 26 15C26.3978 15 26.7794 14.842 27.0607 14.5607C27.342 14.2794 27.5 13.8978 27.5 13.5V11C27.5 10.6025 27.3422 10.2212 27.0613 9.94L20.0613 2.94C19.9219 2.80052 19.7565 2.68987 19.5744 2.61437C19.3923 2.53887 19.1971 2.50001 19 2.5H7C6.33696 2.5 5.70107 2.76339 5.23223 3.23223C4.76339 3.70107 4.5 4.33696 4.5 5V13.5C4.5 13.8978 4.65804 14.2794 4.93934 14.5607C5.22064 14.842 5.60218 15 6 15ZM20 7.125L22.875 10H20V7.125Z'
        fill='#323232'
        {...path}
      />
      <path
        d='M16 21.5V20.5H18.5C18.8978 20.5 19.2794 20.342 19.5607 20.0607C19.842 19.7794 20 19.3978 20 19C20 18.6022 19.842 18.2206 19.5607 17.9393C19.2794 17.658 18.8978 17.5 18.5 17.5H14.5C14.1022 17.5 13.7206 17.658 13.4393 17.9393C13.158 18.2206 13 18.6022 13 19V26C13 26.3978 13.158 26.7794 13.4393 27.0607C13.7206 27.342 14.1022 27.5 14.5 27.5C14.8978 27.5 15.2794 27.342 15.5607 27.0607C15.842 26.7794 16 26.3978 16 26V24.5H17.5C17.8978 24.5 18.2794 24.342 18.5607 24.0607C18.842 23.7794 19 23.3978 19 23C19 22.6022 18.842 22.2206 18.5607 21.9393C18.2794 21.658 17.8978 21.5 17.5 21.5H16Z'
        fill='#323232'
        {...path}
      />
      <path
        d='M28.962 25.6586C28.9172 25.4666 28.8349 25.2852 28.72 25.1249L26.845 22.4999L28.72 19.8749C28.9521 19.5512 29.0461 19.1485 28.9812 18.7555C28.9164 18.3625 28.6981 18.0113 28.3744 17.7793C28.0507 17.5472 27.648 17.4533 27.255 17.5181C26.862 17.5829 26.5109 17.8012 26.2788 18.1249L25 19.9174L23.72 18.1249C23.488 17.8012 23.1368 17.5829 22.7438 17.5181C22.3508 17.4533 21.9481 17.5472 21.6244 17.7793C21.3007 18.0113 21.0824 18.3625 21.0176 18.7555C20.9528 19.1485 21.0467 19.5512 21.2788 19.8749L23.1538 22.4999L21.2788 25.1249C21.1639 25.2852 21.0817 25.4666 21.0369 25.6586C20.992 25.8507 20.9855 26.0497 21.0176 26.2443C21.0497 26.4389 21.1198 26.6253 21.2239 26.7928C21.328 26.9603 21.4641 27.1056 21.6244 27.2205C21.7847 27.3354 21.9661 27.4176 22.1581 27.4625C22.3502 27.5073 22.5492 27.5138 22.7438 27.4817C22.9384 27.4496 23.1248 27.3795 23.2923 27.2754C23.4598 27.1713 23.6051 27.0352 23.72 26.8749L25 25.0774L26.2788 26.8749C26.3937 27.0352 26.5391 27.1713 26.7066 27.2754C26.8741 27.3795 27.0604 27.4496 27.255 27.4817C27.4496 27.5138 27.6486 27.5073 27.8407 27.4625C28.0328 27.4176 28.2141 27.3354 28.3744 27.2205C28.5347 27.1056 28.6708 26.9603 28.7749 26.7928C28.879 26.6253 28.9492 26.4389 28.9812 26.2443C29.0133 26.0497 29.0068 25.8507 28.962 25.6586Z'
        fill='#323232'
        {...path}
      />
    </svg>
  );
}
