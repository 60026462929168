import React from "react"
export default function Filter({ svg }) {
    return <svg
      width='18'
      height='12'
      viewBox='0 0 18 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...svg}
    >
      <path
        d='M4 6H14M1.5 1H16.5M6.5 11H11.5'
        stroke='#667085'
        strokeWidth='1.67'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
}