import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 250px;
  background: white;
  border: 1px solid #eaecf0;
  border-radius: 8px;

  > * {
    pointer-events: none;
  }
`;
