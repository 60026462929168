import React, { useRef, useLayoutEffect, useCallback, forwardRef } from 'react';
import { useObserve } from './hooks/useObserve';
import {
  ModalContainer,
  BodyWrapper,
  HeaderWrapper,
  ModalContents
} from './Modal.style';
import Button from '../Button/Button.index.jsx';
import { getIcon } from '../Icon/getIcon';

function _Header({ style, children }, ref) {
  return (
    <HeaderWrapper ref={ref} style={style}>
      {children}
    </HeaderWrapper>
  );
}
function _Body({ style, children }, ref) {
  return (
    <BodyWrapper ref={ref} style={style}>
      {children}
    </BodyWrapper>
  );
}

function centerModal(height, ref) {
  const windowHeight = window.innerHeight / 2;
  const modalHeight = height / 2;
  const result = windowHeight - modalHeight;

  if (height > 200 && modalHeight <= windowHeight) {
    ref.style.margin = `${result}px auto 0`;
  } else {
    ref.style.margin = `10px auto`;
  }
}

function Modal({
  children,
  isVisible,
  setVisible,
  onClose,
  context,
  options = {},
  modalStyle = {},
  containerStyle = {}
}) {
  const modalRef = useRef();
  const modalContainerRef = useRef();

  let modal = false;
  let setModalVisible = null;
  let restContext = {};

  if (context) {
    const { modals: get, setModalVisible: set, ...rest } = context;
    modal = get;
    setModalVisible = set;
    restContext = rest;
  }

  const resize = () => {
    const modalHeight = modalRef.current.getBoundingClientRect().height;

    if (isVisible || modal.visible) {
      modalRef.current.classList.add('enter');
      centerModal(modalHeight, modalRef.current);
    }
  };

  const close = () => {
    if (
      onClose instanceof Function ||
      setVisible instanceof Function ||
      setModalVisible
    ) {
      modalRef.current.classList.remove('enter');
    }

    setTimeout(() => {
      if (onClose instanceof Function) {
        onClose();
      } else if (setVisible instanceof Function) {
        setVisible(false);
      }

      if (setModalVisible) {
        setModalVisible(modal.id, false);
      }
    }, 350);
  };

  useObserve(
    {
      entries: [modalRef]
    },
    ({ contentRect }, prev) => {
      const prevHeight = Math.ceil(prev.contentRect.height).toFixed(0);
      const currentHeight = Math.ceil(contentRect.height).toFixed(0);

      if (prevHeight !== currentHeight) {
        resize();
      }
    },
    [isVisible, modal.visible]
  );

  useLayoutEffect(() => {
    if (isVisible || modal.visible) {
      resize();
      window.addEventListener('resize', resize);
    }
    return () => {
      if (isVisible || modal.visible) {
        window.removeEventListener('resize', resize);
      }
    };
  }, [isVisible, modal.visible]);

  function renderChildren() {
    if (Array.isArray(children)) {
      const __children = children.map((child, index) => {
        if (child.type.displayName === 'Header') {
          return React.cloneElement(
            child,
            { key: index },
            <>
              {child.props.children}
              <CloseButton onClick={close} />
            </>
          );
        }

        if (typeof child.props.children === 'function') {
          return React.cloneElement(
            child,
            { key: index },
            child.props.children({
              close,
              isVisible,
              context: { modal, ...restContext }
            })
          );
        }

        return child;
      });

      return __children;
    }

    return [];
  }

  const render = useCallback(renderChildren, [isVisible, modal.visible]);

  return (
    <ModalContainer
      ref={modalContainerRef}
      style={containerStyle}
      isVisible={isVisible || modal.visible}
    >
      <ModalContents style={modalStyle} ref={modalRef}>
        {options.memoizedBody ? render() : renderChildren()}
      </ModalContents>
    </ModalContainer>
  );
}

function CloseButton({ onClick }) {
  return (
    <Button
      style={{
        borderRadius: '100vw',
        padding: '0',
        width: '40px',
        height: '40px',
        background: '#F1F1F1',
        border: 'none'
      }}
      Icon={getIcon('close')}
      onClick={onClick}
    />
  );
}

const Header = forwardRef(_Header);
const Body = forwardRef(_Body);

Modal.displayName = 'Modal';
Header.displayName = 'Header';

Modal.Body = Body;
Modal.Header = Header;

export default Modal;
export { Header, Body };
