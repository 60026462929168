import React, { useEffect, useReducer, useRef } from 'react';
import { ImprovedObject } from '../utils/object';
import { runWatcher } from './runWatcher';

class FormState {
  _errors = new ImprovedObject();
  _formState = new ImprovedObject();
  _fields = new ImprovedObject();
  _values = new ImprovedObject();
  _defaultValues = {};
  _inputRefs = new Map();
  _watcher = null;
  validations = {
    required: (value, isRequired) => (isRequired ? !!value : true),
    pattern: (value, pattern) => !!String(value).match(pattern)
  };

  registerProxy() {
    const proxy = new Proxy(this, {
      get(target, property) {
        const _value = target._values[property];
        const _validations = new ImprovedObject(
          target._fields[property].validations
        );
        const _errorMessages = target._fields[property].errorMessages;
        const errors = target._errors[property];

        for (const [key, validationValue] of _validations.entries()) {
          let hasError = false;

          if (validationValue instanceof Function) {
            if (!validationValue(_value, { ...target._values })) {
              hasError = true;
            }
          } else if (!target.validations[key](_value, validationValue)) {
            hasError = true;
          }

          if (hasError) {
            target._errors[property] = {
              type: key,
              message: _errorMessages?.[key] ?? '',
              ref: target._inputRefs.get(property).current
            };

            break;
          }

          if (errors && errors.type === key) {
            delete target._errors[property];
          }
        }
      }
    });

    this._proxy = proxy;
  }
  add(name, options = {}) {
    const { errorMessages = null, ...rest } = options;

    this._fields[name] = {
      ...(Object.keys(rest).length > 0 && { validations: rest }),
      ...(errorMessages && { errorMessages }),
      validate: false
    };
  }
  remove(name) {
    delete this._fields[name];
    delete this._values[name];
    delete this._errors[name];
    this._inputRefs.delete(name);
  }
  set(name, value) {
    this._values[name] = value;
  }
  has(name) {
    return this._fields[name];
  }
  get(name) {
    if (!name) {
      return this.values;
    }
    return this._values[name];
  }
  validateField(name) {
    if (this._fields[name]?.validations) {
      this._proxy[name];
    }
  }
  clean(type) {
    this._errors =
      this._errors._size === 0 ? new ImprovedObject() : this._errors;
    this._values = new ImprovedObject();

    if (type === 'all') {
      this._fields = new ImprovedObject();
      this._inputRefs = new Map();
      this._errors = new ImprovedObject();
    }
  }
  get values() {
    return this._values;
  }
  get errors() {
    return this._errors;
  }
}

const createTrackedRefObject = (state, name) => {
  const obj = {};

  Object.defineProperty(obj, 'current', {
    get: function () {
      return this._current;
    },
    set: function (node) {
      if (node) {
        let stateValue = '';

        if (state._values[name] === 'true' || state._values[name] === 'false') {
          stateValue = Boolean(state._values[name]);
        } else {
          stateValue = state._values[name] ?? '';
        }

        node.value = stateValue;

        if (node?.type === 'checkbox' && stateValue) {
          node.checked = stateValue;
        }
      }

      this._current = node;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(obj, '_current', {
    writable: true
  });

  return obj;
};

export const createFormControl = observers => {
  const state = new FormState();
  state.registerProxy();

  const add = (name, options) => {
    if (!state.has(name)) {
      state.add(name, options);
      state.set(name, state._defaultValues[name] ?? '');

      state._inputRefs.set(name, createTrackedRefObject(state, name));
      delete state._defaultValues[name];
    }

    return {
      name,
      ref: state._inputRefs.get(name),
      onChange: _onChange(name)
    };
  };

  const getValues = () => {
    return state.get();
  };

  const _onChange = name => data => {
    const _ref = state._inputRefs.get(name).current;

    if (_ref.type === 'checkbox') {
      if (_ref.value && data?.target?.checked) {
        const treatedValue =
          _ref.value === 'true' || _ref.value === 'false'
            ? Boolean(_ref.value)
            : _ref.value;
        state.set(name, data?.target?.checked ? treatedValue : false);
      } else {
        state.set(name, data?.target?.checked ?? data);
      }
    } else {
      state.set(name, data?.target?.value ?? data);
    }

    if (state._formState.submitted || state._fields[name].validate) {
      state.validateField(name);
      observers.notify('formState');
    }

    observers.notify('watchers', name, 'change', state);
  };

  const setValue = (name, value, validate) => {
    const input = state._inputRefs.get(name).current;
    input.value = value;

    state.set(name, value);

    if (validate && state._fields[name]?.validations) {
      state.validateField(name);
      state._fields[name].validate = true;
      observers.notify('formState');
    }

    observers.notify('watchers', name, 'set', state);
  };

  const handleSubmit = (onSuccess, onFailure) => async e => {
    e.preventDefault();

    state._formState = { ...state._formState, submitted: true };

    state._fields._keys.forEach(key => {
      state.validateField(key);
    });

    observers.notify('watchers', state._fields, 'submit', state);

    if (state._errors._size === 0) {
      onSuccess && (await onSuccess({ ...state._values }));
    } else {
      observers.notify('formState');
      onFailure && (await onFailure({ ...state._errors }));
    }
  };

  const remove = name => {
    state.remove(name);
  };

  const removeAll = () => {
    state._fields._keys.forEach(key => state.remove(key));
  };

  const reset = name => {
    if (name) {
      const ref = state._inputRefs.get(name);

      if (ref && ref.current) {
        if (ref.current.type === 'checkbox') {
          ref.current.checked = false;
        } else {
          ref.current.value = '';
        }
      }

      state._values[name] = '';
      delete state._errors[name];
    } else {
      state._inputRefs.forEach(input => {
        if (input.current.type === 'checkbox') {
          input.current.checked = false;
        } else {
          input.current.value = '';
        }
      });
      state.clean();
      if (state._formState.submitted && state._errors._size > 0) {
        state._fields._keys.forEach(key => {
          state.validateField(key);
        });

        state._formState.reset = true;
        observers.notify('formState');
      }
    }
  };

  const watchers = watchers => {
    const setWatcher = (cb, wt) => {
      state._watcher = {
        unsubscribe: () => observers.unsubscribe('watchers', cb),
        callback: cb,
        watchers: wt.map(watcher => {
          const { watch, ...rest } = watcher;
          let _options = new ImprovedObject(rest);

          _options =
            _options._size > 0
              ? {
                  ..._options,
                  ...(_options.where && {
                    where: new ImprovedObject(_options.where)
                  })
                }
              : null;

          return {
            watch,
            ..._options
          };
        })
      };
    };
    if (!state._watcher) {
      const cb = observers.subscribe('watchers', runWatcher);
      setWatcher(cb, watchers);
    } else if (
      state._watcher &&
      !observers.has('watchers', state._watcher.callback)
    ) {
      const cb = observers.subscribe('watchers', runWatcher);
      setWatcher(cb, watchers);
    }
    return state._watcher.unsubscribe;
  };

  watchers.remove = watcher => {
    if (!state._watcher) {
      throw new Error('Defina um watcher antes de tentar dar um push');
    }

    const i = state._watcher.watchers.indexOf(watcher);

    if (i !== -1) {
      state._watcher.watchers.splice(i, 1);
    }
  };

  watchers.push = watcher => {
    if (!state._watcher) {
      throw new Error('Defina um watcher antes de tentar dar um push');
    }

    let refToObj;

    if (observers.has('watchers', state._watcher.callback)) {
      const { watch, ...rest } = watcher;
      let _options = new ImprovedObject(rest);

      _options =
        _options._size > 0
          ? {
              ..._options,
              ...(_options.where && {
                where: new ImprovedObject(_options.where)
              })
            }
          : null;

      refToObj = {
        watch,
        ..._options
      };
      state._watcher.watchers.push(refToObj);
    }

    return refToObj;
  };

  return {
    add,
    getValues,
    setValue,
    handleSubmit,
    remove,
    removeAll,
    reset,
    watchers,
    state,
    _subscribe: observers.subscribe,
    _unsubscribe: observers.unsubscribe,
    _notify: observers.notify,
    _listeners: observers.listeners
  };
};

export const createFormObserver = () => {
  const listeners = {
    formState: [],
    watchers: []
  };

  const subscribe = (type, fn) => {
    listeners[type].push(fn);
    return fn;
  };
  const unsubscribe = (type, fn) => {
    listeners[type].splice(listeners[type].indexOf(fn), 1);
  };
  const notify = (type, ...args) => {
    listeners[type].forEach(listener => listener(...args));
  };
  const has = (type, fn) => {
    return new Set(listeners[type]).has(fn);
  };

  return {
    subscribe,
    unsubscribe,
    notify,
    has,
    listeners
  };
};

const useForm = (context, options) => {
  const [_, dispatch] = useReducer(v => v + 1, 0);
  const errors = useRef(new ImprovedObject({}));
  const usingRenderProps = useRef(false);

  if (Object.keys(context.state._defaultValues).length === 0) {
    context.state._defaultValues = options?.defaultValues ?? {};
  }

  const shouldRender = () => {
    if (!usingRenderProps.current) return;

    const { _size: prevErrorsQuantity } = errors.current;
    const { _size: currentErrorsQuantity } = context.state._errors;

    if (context.state._formState.reset) {
      dispatch();
      if (currentErrorsQuantity > 0) {
        errors.current = new ImprovedObject(context.state._errors);
      }
      context.state._formState.reset = false;
      return;
    }

    if (prevErrorsQuantity && currentErrorsQuantity === 0) {
      dispatch();
      errors.current = new ImprovedObject(context.state._errors);
      return;
    }

    if (currentErrorsQuantity > 0) {
      if (currentErrorsQuantity !== prevErrorsQuantity) {
        dispatch();
        errors.current = new ImprovedObject(context.state._errors);
        return;
      }
      for (const key of context.state._errors._keys) {
        if (context.state._errors[key].type !== errors.current[key].type) {
          dispatch();
          errors.current = new ImprovedObject(context.state._errors);
        }
      }
    }
  };

  useEffect(() => {
    if (context.state._errors._size > 0) {
      errors.current = new ImprovedObject(context.state._errors);
    }

    const unsub = context._subscribe('formState', shouldRender);
    return () => {
      context._unsubscribe('formState', unsub);

      // if (process.env.NODE_ENV !== 'development') {
      //   const { formState } = context._listeners;

      //   if (formState.length === 0) {
      //     context.state.clean('all');
      //   }
      // }
    };
  }, []);

  const formState = {};

  Object.defineProperty(formState, 'errors', {
    get: () => {
      usingRenderProps.current = true;
      return { ...context.state._errors };
    }
  });

  return {
    ...context,
    formState
  };
};

export default function createPlickForm(name) {
  const hookName = `use${name
    .replace(/(^\w{1}).+/, '$1')
    .toUpperCase()}${name.slice(1)}Form`;

  const observers = createFormObserver();
  const context = createFormControl(observers);

  return {
    [hookName]: useForm,
    context
  };
}
