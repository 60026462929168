import React, { useEffect } from 'react';
import setSidebarVisible from '../../logic/utils/sidebar/setSidebarVisible';
import { mappedButtons } from '../Buttons/Buttons.index';
import Icon from '../Icon/Icon.index';
import { Footer, Header, MenuStyled, MenuUl } from './Menu.style';

export default function Menu({ optionView, mappedList, user }) {
  const handleClick = fn => {
    if (fn instanceof Function) {
      const activeButton = [...mappedButtons.values()].find(
        button => button.state.currentStyle === 'active'
      );

      activeButton.setState({
        currentStyle: 'initial',
        iconStyle: {
          path: { stroke: '#fff' }
        }
      });
      setSidebarVisible(false);
      fn();
    }
  };

  const checkPermissions = list => {
    const allowedOptions = {
      list: []
    };

    list.forEach(item => {
      const value = !(item instanceof Array) ? item : item[1];

      const isAllowed =
        value.accessLevel.length > 0
          ? value.accessLevel.find(level => level === user.role)
          : true;

      if (isAllowed) {
        allowedOptions.list.push(item);
      }
    });

    return allowedOptions;
  };

  const renderList = () => {
    if (mappedList.length === 0) {
      return <></>;
    }

    const { list } = checkPermissions(new Set(mappedList));

    return list.map(item => (
      <li
        key={item instanceof Array ? item[0] : item.feature}
        onClick={() =>
          handleClick(item instanceof Array ? item[1].onClick : item.onClick)
        }
      >
        <p>{item instanceof Array ? item[1].title : item.title}</p>
      </li>
    ));
  };

  return (
    <MenuStyled>
      <Header>
        <h2>{optionView.title}</h2>
        <MenuUl>{renderList()}</MenuUl>
      </Header>
      <Footer>
        <div className='info'>
          <h5>{user.name}</h5>
          <p>{user.email}</p>
        </div>
        <div className='logout' onClick={user.logout}>
          <Icon name='logout' />
        </div>
      </Footer>
    </MenuStyled>
  );
}
