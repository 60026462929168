import styled, { css } from 'styled-components';

const styles = {
  initial: css`
    background: rgba(255, 255, 255, 0.3);
    &:hover {
      background: rgba(255, 255, 255, 0.5);
    }
  `,
  active: css`
    background: rgba(255, 255, 255, 1);
    &:hover {
      background: rgba(255, 255, 255, 1);
    }
  `
};

const getDinamicStyle = ({ state }) => {
  return styles[state.currentStyle];
};

const getDefaultStyle = () => {
  return css`
    border: none;
    width: calc(100% / 1.25);
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 48px;
    transition: background ease 280ms;
  `;
};

export const Container = styled.button`
  ${getDefaultStyle}
  ${getDinamicStyle}

  svg {
    path {
      transition: all ease 350ms;
    }
  }
`;

export const ProfileContainer = styled.button`
  ${getDefaultStyle}
  width: 48px;
  flex-basis: 48px;
  background: none;
  padding: 0px;

  img {
    width: 100%;
    height: 100%;
    border: 2px solid white;
    border-radius: 100vw;
    object-fit: cover;
  }
`;
