import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${function ({ size }) {
    return size
      ? size
      : css`
          width: auto;
          height: auto;
        `;
  }}
  display: flex;
  flex-flow: column nowrap;
  ${function ({ disabled }) {
    return (
      disabled &&
      css`
        opacity: 0.5;
      `
    );
  }}
`;

export const HiddenTextArea = styled.textarea`
  display: none;
`;

export const StyledTextArea = styled.textarea`
  background: white;
  min-height: 50px;
  height: auto;
  width: auto;
  padding: 10px 14px;
  box-sizing: border-box;
  outline: none;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  font-size: 16px;
  resize: none;
  transition: box-shadow ease 260ms, border ease 260ms;
  &:focus {
    ${({ error }) =>
      error
        ? css`
            box-shadow: 0px 0px 0px 3px rgba(255, 66, 66, 0.3);
          `
        : css`
            box-shadow: 0px 0px 0px 3px rgba(0, 191, 111, 0.3);
          `};
    ${({ error }) =>
      error
        ? css`
            border: 1px solid #ff4242;
          `
        : css`
            border: 1px solid rgba(0, 191, 111, 1);
          `};
  }
  &:hover:not(:disabled) {
    ${({ error }) =>
      error
        ? css`
            border: 1px solid #ff4242;
          `
        : css`
            border: 1px solid rgba(0, 191, 111, 1);
          `};
  }
  &:disabled {
    cursor: not-allowed;
  }

  ${({ error }) => {
    return (
      error &&
      css`
        border: 1px solid #ff4242;
      `
    );
  }}
`;

export const ErrorMessage = styled.span`
  color: #ff4242;
  font-size: 12px;
`;

export const Label = styled.label`
  font-size: 14px;
  margin: 0 0 7px;
  color: #344054;
`;
