import React from 'react';

export default function PieChartCircle({
  color,
  percentage,
  dashOffset,
  circumference,
  strokeWidth,
  ...rest
}) {
  return (
    <circle
      {...rest}
      fill='transparent'
      stroke={color}
      strokeDasharray={`calc(${percentage} * ${circumference} / 100) ${circumference}`}
      strokeWidth={strokeWidth}
      {...(dashOffset ? { strokeDashoffset: dashOffset } : {})}
    />
  );
}
