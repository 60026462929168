import React, {
  forwardRef,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from 'react';
import { ChevronWrapper, Wrapper } from './Multiselect.style';
import SelectInput from '../Select/components/Input';
import Menu from './components/Menu';
import PropTypes from 'prop-types';

import { getIcon } from '../Icon/getIcon';

const Chevron = getIcon('chevron');

function _Multiselect(
  {
    name,
    inputStyle = {},
    containerStyle = null,
    menuStyle = {},
    inputProps = {},
    options = [],
    onChange,
    error = {},
    loaders = {},
    autocomplete,
    filter
  },
  ref
) {
  if (filter && !autocomplete) {
    throw new Error('Trying to use filter without autocomplete prop');
  }

  const _ref = useRef();
  const filterInputRef = useRef();
  let inputRef = ref && Object.keys(ref)[0] === 'current' ? ref : _ref;

  const menuRef = useRef();
  const chevronRef = useRef();
  const nativeSetter = useRef();
  const optionsCache = useRef({ all: options, filtered: '' });
  const [_options, setOptions] = useState(options);
  const [filterError, setFilterError] = useState('');

  const generateLabel = targetOptions => {
    const filtered = targetOptions.filter(option => option.checked);

    const label = filtered.reduce(
      (acc, option) => (acc === '' ? option.label : `${acc}, ${option.label}`),
      ''
    );

    nativeSetter.current(label);

    return {
      filtered,
      label
    };
  };

  const handleChanges = value => {
    const newOptionsState = _options.map(option =>
      option.value === value.value ? value : option
    );

    optionsCache.current.filtered = newOptionsState;

    const changedIndex = optionsCache.current.all.findIndex(d => {
      return d.value === value.value;
    });
    optionsCache.current.all.splice(changedIndex, 1, value);

    const newValue = optionsCache.current.all
      .filter(option => option.checked)
      .map(option => option.value);

    if (onChange instanceof Function) {
      onChange(newValue);
    }

    generateLabel(optionsCache.current.all);
    setOptions(newOptionsState);
  };

  const handleSearch = result => {
    setOptions(result);

    if (filterError) {
      setFilterError('');
    }
  };

  const noDebounce = async event => {
    const { value } = event.target;

    if (menuRef.current.dataset.hide === 'true') {
      menuRef.current.dataset.hide = 'false';
    }

    try {
      if (value === '') {
        handleSearch(optionsCache.current.all);
      } else {
        const result = optionsCache.current.all.filter(item =>
          filter(item, value)
        );

        if (result.length === 0) throw new Error('Nenhum resultado.');

        handleSearch(result);
      }
    } catch (e) {
      setFilterError(e.message);
    }
  };

  useEffect(() => {
    setOptions(options);
    generateLabel(options);
    optionsCache.current.all = options;
  }, [options]);

  useLayoutEffect(() => {
    const nativeValueSetter = Object.getOwnPropertyDescriptor(
      window.HTMLInputElement.prototype,
      'value'
    ).set;

    nativeSetter.current = nativeValueSetter.bind(inputRef.current);

    Object.defineProperty(inputRef.current, 'value', {
      set: function (newValue) {
        nativeValueSetter.call(this, generateLabel(newValue));
      }
    });
  }, []);

  useLayoutEffect(() => {
    if (autocomplete) {
      filterInputRef.current.addEventListener('input', noDebounce);
    }

    return () => {
      if (autocomplete) {
        filterInputRef.current.removeEventListener('input', noDebounce);
      }
    };
  }, [_options, filterError]);

  const handleClickOutside = event => {
    const path = event.composedPath();

    if (
      menuRef.current.dataset.hide === 'false' &&
      !path.includes(inputRef.current) &&
      !path.includes(menuRef.current)
    ) {
      menuRef.current.dataset.hide = 'true';
      chevronRef.current.dataset.up = 'false';

      if (autocomplete && filterInputRef.current.value !== '') {
        filterInputRef.current.value = '';
        handleSearch(optionsCache.current.all);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      {loaders.wrapper && loaders.wrapper}
      <Wrapper
        hide={loaders.wrapper}
        {...(containerStyle && { style: containerStyle })}
        onMouseDown={() => {
          menuRef.current.dataset.hide = 'false';
          chevronRef.current.dataset.up = 'true';
        }}
      >
        <SelectInput
          type='multiselect'
          ref={inputRef}
          {...inputProps}
          name={name}
          error={error.message}
          style={{ ...inputStyle, ...inputProps.style }}
          autoComplete='off'
          Chevron={
            <ChevronWrapper ref={chevronRef}>
              <Chevron />
            </ChevronWrapper>
          }
          readOnly
        />

        <Menu
          ref={menuRef}
          inputRef={filterInputRef}
          style={menuStyle}
          handleChanges={handleChanges}
          options={_options}
          autocomplete={autocomplete}
          error={filterError}
        />
      </Wrapper>
    </>
  );
}

const Multiselect = forwardRef(_Multiselect);

const OptionShape = PropTypes.shape({
  value: PropTypes.any,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool
});

Multiselect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  inputStyle: PropTypes.object,
  containerStyle: PropTypes.object,
  menuStyle: PropTypes.object,
  inputProps: PropTypes.shape({
    placeholder: PropTypes.string,
    label: PropTypes.string
  }),
  options: PropTypes.arrayOf(OptionShape).isRequired,
  onChange: PropTypes.func,
  error: PropTypes.object
};

export default Multiselect;
