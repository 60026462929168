import styled from 'styled-components'

export const ModalOverlay = styled.section`
  display: ${(props) => props.display};
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
`

export const ModalContainer = styled.article`
  display: grid;
  background-color: hsl(0,0%,100%);
  position: absolute;
  border-radius: 10px;
  grid-template-rows: minmax(50px, auto) auto minmax(50px, auto);
  top: 50%;
  left: 50%;
  min-height: 30%;
  transform: translate(-50%, -50%);
  grid-template-areas:
    'modal-header'
    'modal-content'
    'modal-footer';
  gap: 25px;
  max-width: clamp(400px, 50%, 1000px);
`
export const ModalHeader = styled.header`
  grid-area: modal-header;
  position: relative;
  display: flex;
  align-items: center;
  padding: 25px 75px 0px 45px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`
export const CircleClose = styled.div`
  top: 18px;
  right:18px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background-color: hsl(0, 0%, 95%);
  cursor: pointer;
`

const ModalBody = styled.section`
  padding: 0px 45px;
`
export const ModalContent = styled(ModalBody)`
  grid-area: modal-content;
  min-height: 0px;
  max-height: 425px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 18px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px 10px hsl(0,0%,85%);
    border: solid 7px transparent;
    border-radius:100vw;
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 10px hsl(0,0%,65%);
    border: solid 7px transparent;
    border-radius:100vw;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb:hover {
    box-shadow: inset 0 0 10px 10px hsl(0,0%,45%);
    background-color: transparent;
  }
`

export const ModalFooter = styled(ModalBody)`
  grid-area: modal-footer;
  display: flex;
  align-items: center;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 0px 45px 30px;
`
