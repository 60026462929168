class Chart {
  constructor(data, circumference) {
    const calcDistance = getElementsDistance(circumference);

    this.elementsDistance = new Map();
    this.total = data.reduce((acc, { value }) => (acc += value), 0);
    this.data_ = this.createData({ data, calcDistance });
  }

  createData({ data, calcDistance }) {
    const preData = data.map(({ value, ...rest }, index) => {
      const percentage = calcPercentage(value, this.total);

      this.elementsDistance.set(index, {
        distance: calcDistance(percentage)
      });

      return { value, percentage, ...rest };
    });

    const finalData = new Map(
      Object.entries(preData).map(([key, value]) => [Number(key), value])
    );

    calcFinalDistance(finalData, this.elementsDistance);

    return finalData;
  }
}

class PieChart_ {
  constructor(values) {
    const { data, showLegend, DATA_LENGTH_IS_ABOVE_SIX } = values;

    const DIAMETER_STROKE_DIVISOR = 2.5;

    this.circumference = showLegend ? 113.097 : 78.54;
    this.strokeWidth = showLegend
      ? 35.9 / DIAMETER_STROKE_DIVISOR
      : 25 / DIAMETER_STROKE_DIVISOR;
    this.chart = new Chart(data, this.circumference);
    this.legend = showLegend
      ? this.generateLegendsData(data, DATA_LENGTH_IS_ABOVE_SIX)
      : false;
  }

  generateLegendsData(data, DATA_LENGTH_IS_ABOVE_SIX) {
    return DATA_LENGTH_IS_ABOVE_SIX
      ? (() => {
          const firstGroup = data.slice(0, 6);
          const lastGroup = data.slice(6);

          const largestFirstGroupText = firstGroup.reduce((acc, { legend }) => {
            return legend.length > acc.legend.length
              ? { legend }
              : { legend: acc.legend };
          });
          const largestLastGroupText = lastGroup.reduce((acc, { legend }) => {
            return legend.length > acc.legend.length
              ? { legend }
              : { legend: acc.legend };
          });

          return {
            firstGroup,
            lastGroup,
            largestFirstGroupText: largestFirstGroupText.legend,
            largestLastGroupText: largestLastGroupText.legend
          };
        })()
      : data;
  }
}

const calcPercentage = (value, total) => (value / total) * 100;
const calcFinalDistance = (data, elementsDistance) => {
  const modifiedData = new Map(data);
  modifiedData.delete(0);

  modifiedData.forEach((value, key) => {
    const { distance } = elementsDistance.get(key - 1);
    data.set(key, { ...value, distance });
  });
};
const getElementsDistance = circumference => {
  let prevOffset = 0;

  return percentage => {
    const distanceByCircuference = (percentage * circumference) / 100;

    return -(prevOffset = distanceByCircuference + prevOffset);
  };
};

export { PieChart_ };
