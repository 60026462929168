import React from 'react';
import PropTypes from 'prop-types';
import ListContent from './components/ListContent/ListContent.index';
import Paginator from '../../../Paginator/Paginator.index.jsx';

import { ListWrapper, ListHeader, PaginatorWrapper } from './List.style';

export default function List({ header, list, paginator }) {
  return (
    <ListWrapper>
      <ListHeader>{header}</ListHeader>
      <ListContent list={list} />
      <PaginatorWrapper>
        <Paginator {...paginator} />
      </PaginatorWrapper>
    </ListWrapper>
  );
}

List.propTypes = {
  header: PropTypes.element.isRequired,
  list: PropTypes.object.isRequired,
  paginator: PropTypes.object.isRequired
};
