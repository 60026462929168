import styled, { css } from 'styled-components';

const getSize = ({ size }) => {
  return size
    ? css`
        width: ${size.width ?? 'auto'};
        height: ${size.height ?? 'auto'};
      `
    : css`
        width: auto;
        height: auto;
      `;
};

const getLayoutPosition = ({ layout }) => {
  return layout
    ? css`
        position: ${layout};
      `
    : css`
        position: relative;
      `;
};

const getWrapperPosition =
  type =>
  ({ position }) => {
    const initialValues = {
      top: css`
        left: 50%;
        opacity: 0;
        transform: translate(-50%, -50%);
      `,
      bottom: css`
        left: 50%;
        opacity: 0;
        top: 100%;
        transform: translate(-50%, -100%);
      `,
      right: css`
        left: calc(100% + 10px);
        top: 50%;
        opacity: 0;
      `,
      left: css`
        right: calc(100% + 10px);
        top: 50%;
        opacity: 0;
      `,
      default: css`
        left: 50%;
        opacity: 0;
        transform: translate(-50%, -50%);
      `
    };
    const activeValues = {
      top: css`
        transform: translate(-50%, -100%);
        opacity: 1;
      `,
      bottom: css`
        transform: translate(-50%, 0);
        opacity: 1;
      `,
      right: css`
        transform: translate(0, -50%);
        opacity: 1;
      `,
      left: css`
        transform: translate(0, -50%);
        opacity: 1;
      `,
      default: css`
        transform: translate(-50%, -100%);
        opacity: 1;
      `
    };

    const values = new Map([
      ['initial', initialValues],
      ['active', activeValues]
    ]);

    return values.get(type)[position];
  };

const getArrowPosition =
  type =>
  ({ position }) => {
    const initialValues = {
      top: css`
        left: 50%;
      `,
      bottom: css`
        left: 50%;
        top: 100%;
      `,
      right: css`
        left: calc(100% + 10px);
        top: 50%;
      `,
      left: css`
        right: calc(100% + 10px);
        top: 50%;
      `,
      default: css`
        left: 50%;
      `
    };

    const activeValues = {
      top: css`
        opacity: 1;
        transform: translateX(-50%);
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 4px solid #333333;
        border-bottom: 4px solid transparent;
      `,
      bottom: css`
        opacity: 1;
        transform: translate(-50%, -100%);
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 4px solid transparent;
        border-bottom: 4px solid #333333;
      `,
      right: css`
        opacity: 1;
        transform: translate(-100%, -50%);
        border-left: 4px solid transparent;
        border-right: 4px solid #333333;
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
      `,
      left: css`
        opacity: 1;
        transform: translate(100%, -50%);
        border-left: 4px solid #333333;
        border-right: 4px solid transparent;
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
      `,
      default: css`
        opacity: 1;
        transform: translateX(-50%);
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 4px solid #333333;
        border-bottom: 4px solid transparent;
      `
    };

    const values = new Map([
      ['initial', initialValues],
      ['active', activeValues]
    ]);

    return values.get(type)[position];
  };

export const Wrapper = styled.div`
  ${getSize}
  ${getLayoutPosition}
  z-index: 3;

  &::before {
    content: attr(data-title);
    color: white;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #333333;
    position: absolute;
    line-height: 24px;
    padding: 3px 10px;
    white-space: nowrap;
    min-width: 30px;
    max-width: max-content;
    overflow-wrap: break-word;
    border-radius: 4px;
    width: auto;
    height: auto;
    transition: opacity ease 150ms, transform ease 150ms;
    pointer-events: none;
    ${getWrapperPosition('initial')}
  }
  &.no__transition::before {
    transition: opacity ease 0ms, transform ease 0ms;
  }
  &.active::before {
    ${getWrapperPosition('active')}
  }

  .arrow-up {
    opacity: 0;
    transition: opacity ease 250ms, transform ease 150ms;
    width: 0;
    height: 0;
    position: absolute;
    ${getArrowPosition('initial')}
  }

  &.no__transition .arrow-up {
    transition: opacity ease 0ms, transform ease 0ms;
  }
  &.active .arrow-up {
    ${getArrowPosition('active')}
  }
`;
