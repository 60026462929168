import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  StyledInput,
  ErrorMessage,
  Label
} from '../../Inputs/Input/Input.style';
import { InputContainer, InputWrapper } from '../Select.style';

function Input({ style, name, label, error, disabled, Chevron, ...rest }, ref) {
  return (
    <InputContainer disabled={disabled}>
      {label && <Label htmlFor={name}>{label}</Label>}
      <InputWrapper>
        <StyledInput
          ref={ref}
          disabled={disabled}
          style={style}
          error={error}
          type='text'
          {...rest}
        />
        {Chevron}
      </InputWrapper>
      {error && error?.message && <ErrorMessage>{error.message}</ErrorMessage>}
    </InputContainer>
  );
}

export default forwardRef(Input);

Input.propTypes = {
  ref: PropTypes.any,
  handleInitialValue: PropTypes.func,
  deps: PropTypes.array,
  freezeOnMount: PropTypes.bool,
  style: PropTypes.object,
  name: PropTypes.string,
  startSearch: PropTypes.func,
  delay: PropTypes.number
};
