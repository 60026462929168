import React, { forwardRef } from 'react';
import {
  ErrorMessage,
  StyledTextArea,
  Label,
  Container
} from './TextArea.style';
import { mergeStyles } from '../../../styles/mergeStyles';

function TextArea(props, ref) {
  const {
    width,
    height,
    style,
    label,
    error,
    containerSize,
    containerStyle,
    disabled = false,
    ...rest
  } = props;
  return (
    <Container disabled={disabled} size={containerSize} style={containerStyle}>
      {label && <Label htmlFor={rest.name}>{label}</Label>}
      <StyledTextArea
        ref={ref}
        style={mergeStyles({ width, height, ...style })}
        type='text'
        disabled={disabled}
        {...rest}
        error={error}
      />
      {error && error?.message && <ErrorMessage>{error.message}</ErrorMessage>}
    </Container>
  );
}

export default forwardRef(TextArea);
