import React from "react"
import Button from '../../../../../../../../components/Button/Button.index.jsx'
import { 
    SuccessHeaderTitle,
    SuccessContainer,
    SuccessMessage,
    SuccessFooter
} from "./Success.style"

export default function useSuccessEvent ({payload, setters}) {
    setters.setConfigs(payload?.configs)

    const header = []
    const content = []
    const footer = []

    mountHeader(header, payload)
    mountContent(content, payload)
    mountFooter(footer, setters.closeModal)

    setters.setHeader(header)
    setters.setContent(content)
    setters.setFooter(footer)
    setters.openModal()
}

function mountHeader (header, payload) {
    const {title} = {...payload}
    header.push(<SuccessHeaderTitle>{title}</SuccessHeaderTitle>)
}

function mountContent (content, payload) {
    const {message} = {...payload}
    content.push(
        <SuccessContainer>
          <SuccessMessage>{message}</SuccessMessage>
        </SuccessContainer>
    )
}

function mountFooter (footer, closeModal) {
    footer.push(<SuccessFooter><Button title={'OK'} onClick={closeModal}/></SuccessFooter>)
}

