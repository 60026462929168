function StackIcon({ svg, path, ...rest }) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...svg}
      {...rest}
    >
      <path
        d='M21.9713 15.9375C22.12 16.1953 22.1605 16.5016 22.0839 16.7893C22.0073 17.0769 21.8198 17.3225 21.5625 17.4722L12.5625 22.7222C12.3904 22.8227 12.1946 22.8756 11.9953 22.8756C11.796 22.8756 11.6003 22.8227 11.4281 22.7222L2.42813 17.4722C2.17029 17.3219 1.98272 17.0753 1.90668 16.7867C1.83064 16.4981 1.87236 16.1911 2.02266 15.9333C2.17296 15.6754 2.41954 15.4879 2.70814 15.4118C2.99674 15.3358 3.30373 15.3775 3.56157 15.5278L11.9991 20.4478L20.4375 15.5278C20.6954 15.3794 21.0015 15.3392 21.289 15.416C21.5764 15.4928 21.8217 15.6803 21.9713 15.9375ZM20.4375 11.0278L12 15.9478L3.56719 11.0278C3.31008 10.8898 3.00939 10.857 2.7286 10.9365C2.44781 11.016 2.20889 11.2015 2.06229 11.4538C1.91568 11.7061 1.87287 12.0056 1.94288 12.2889C2.01289 12.5722 2.19024 12.8172 2.4375 12.9722L11.4375 18.2222C11.6096 18.3227 11.8054 18.3756 12.0047 18.3756C12.204 18.3756 12.3997 18.3227 12.5719 18.2222L21.5719 12.9722C21.8297 12.8218 22.0172 12.5751 22.0932 12.2864C22.1691 11.9977 22.1273 11.6906 21.9769 11.4328C21.8265 11.175 21.5798 10.9874 21.2911 10.9115C21.0024 10.8355 20.6953 10.8774 20.4375 11.0278ZM1.875 7.49999C1.87537 7.30287 1.92753 7.1093 2.02625 6.93868C2.12497 6.76805 2.26679 6.62637 2.4375 6.5278L11.4375 1.2778C11.6098 1.17787 11.8055 1.12524 12.0047 1.12524C12.2039 1.12524 12.3996 1.17787 12.5719 1.2778L21.5719 6.5278C21.7419 6.62682 21.883 6.76871 21.9811 6.9393C22.0792 7.10989 22.1308 7.30322 22.1308 7.49999C22.1308 7.69676 22.0792 7.89009 21.9811 8.06069C21.883 8.23128 21.7419 8.37316 21.5719 8.47218L12.5719 13.7222C12.3997 13.8227 12.204 13.8756 12.0047 13.8756C11.8054 13.8756 11.6096 13.8227 11.4375 13.7222L2.4375 8.47218C2.26679 8.37362 2.12497 8.23193 2.02625 8.06131C1.92753 7.89068 1.87537 7.69712 1.875 7.49999ZM5.23313 7.49999L12 11.4478L18.7669 7.49999L12 3.55218L5.23313 7.49999Z'
        fill='white'
        {...path}
      />
    </svg>
  );
}

export default StackIcon;
