import React, { forwardRef } from 'react';
import Input from '../Input/Input.index.jsx';
import { masks } from './masks.js';

function MaskedInput({ onChange, mask, ...props }, ref) {
  const _onChange = ({ currentTarget }) => {
    const formatted = executeFormat(currentTarget);

    ref.current.value = formatted;
    onChange(formatted);
  };

  function executeFormat(target) {
    const type = mask;

    if (masks?.[type]) {
      return masks[type](target.value);
    } else {
      console.warn('Não há formatação para esse tipo');
    }
    return target.value;
  }

  return <Input ref={ref} onChange={_onChange} {...props} />;
}

export default forwardRef(MaskedInput);
