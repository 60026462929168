import styled from 'styled-components';

export const Frame = styled.section`
  grid-area: ${({ area }) => area};
  width: 100%;
  height: 100%;
  /* border: 1px solid hsl(220, 17%, 93%); */
  border-radius: 0px 0px 8px 8px;
  display: flex;
  flex-direction: column;
  border: 1px solid #eaecf0;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 8px;
`;
export const StoryDashboardWrapper = styled.main`
  margin: 0;
  padding: 0;
  display: flex;
  width: 100%;
  height: auto;
`;
export const StoryDashboard = styled.div`
    position: relative;
    display: grid;
    width: 100%;
    grid-template-areas:
      "row1_col1 row1_col2"
      "row2_col1 row2_col2";
    gap: 25px;
    padding: 25px;
    grid-template-columns:repeat(auto-fit, minmax(calc(50% - 50px),1fr));
    grid-template-rows:auto;
    background-color: #FFF;

    & > section:nth-child(1) {
      grid-area: row1_col1;
      background-color: hsl(206, 88%, 20%);
    }
    & > section:nth-child(2) {
      grid-area: row1_col2;
      background-color: hsl(154, 99%, 38%);
    }
    & > section:nth-child(3) {
      grid-area: row2_col1;
      background-color: hsl(61, 92%, 63%);
    }
    & > section:nth-child(4) {
      grid-area: row2_col2;
      background-color: hsl(4, 88%, 58%);
    }
  }
`;

export const SingleWidgetWrapper = styled.main`
  margin: 0;
  padding: 25px 0;
  display: flex;
  width: 100%;
  justify-content: center;
  & > div {
    position: relative;
    grid-template-areas: 'row2_col1';
    width: 500px;
    height: 500px;
  }
`;
