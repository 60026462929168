/* eslint-disable no-unused-vars */
import React, { forwardRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import Button from '../../Button/Button.index.jsx';
import { Item, StyledDefaultLoading, StyledError, StyledMenu } from '../styles';

const labelProps = {
  className: 'autocomplete-item-label',
  style: {
    flexShrink: 1,
    flexGrow: 0.9,
    flexBasis: 0,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  }
};
function Error({ error, errorConfig, stateConfig }) {
  const { state } = stateConfig;

  return (
    <StyledError data-hide-error={state.loading}>
      <h1>{error}</h1>

      {errorConfig.addItem && (
        <Button
          type='button'
          onClick={errorConfig.handleAdd}
          onMouseDown={e => e.preventDefault()}
        >
          {errorConfig.buttonAddTitle ?? 'Inserir na lista'}
        </Button>
      )}
    </StyledError>
  );
}

function DefaultLoading() {
  return (
    <StyledDefaultLoading>
      <p>Carregando...</p>
    </StyledDefaultLoading>
  );
}
function Menu(
  {
    menuStyle,
    inputRef,
    errorConfig,
    extractKey,
    stateConfig,
    renderItem,
    handleSelect,
    fixedItems,
    loader
  },
  ref
) {
  const { state } = stateConfig;
  const checkFixedItems = (item, index) => {
    if (fixedItems.length > 0) {
      if (fixedItems[index]) {
        return fixedItems[index].value;
      }
    }

    if (extractKey) {
      return extractKey(item);
    }

    return index;
  };

  const _renderItem = (item, index) => {
    if (fixedItems.length > 0) {
      if (fixedItems[index]) {
        return fixedItems[index].label;
      }
    }

    return renderItem(item, labelProps);
  };

  const items = useMemo(() => {
    const items_ = state.data.map((item, index) => (
      <Item
        key={checkFixedItems(item, index)}
        selected={state.options[index].value === state.selected}
        data-hide-item={state.loading}
        onClick={() => handleSelect(index)}
        onMouseDown={e => e.preventDefault()}
      >
        {_renderItem(item, index)}
      </Item>
    ));
    return items_;
  }, [state.data, state.loading]);

  const loaderView = () => {
    if (state.loading) {
      return loader ? loader : <DefaultLoading />;
    }
    return <></>;
  };
  const itemsView = () => {
    return state.errors.length ? (
      <Error
        error={state.errors[0]}
        stateConfig={stateConfig}
        errorConfig={errorConfig}
      />
    ) : (
      items
    );
  };

  return (
    <StyledMenu
      style={menuStyle}
      ref={ref}
      data-hide='true'
      openMenu={state.data.length}
      showError={state.errors.length}
      showLoader={state.loading}
    >
      {loaderView()}
      {itemsView()}
    </StyledMenu>
  );
}

const Menu_ = forwardRef(Menu);
export default Menu_;

Menu.propTypes = {
  useFormik: PropTypes.bool,
  stateConfig: PropTypes.object,
  renderItem: PropTypes.func,
  formikConfig: PropTypes.object,
  inputRef: PropTypes.object,
  handleSelect: PropTypes.func,
  errorConfig: PropTypes.object,
  extractKey: PropTypes.func,
  menuStyle: PropTypes.object
};

Error.propTypes = {
  error: PropTypes.string,
  stateConfig: PropTypes.object,
  errorConfig: PropTypes.object
};
