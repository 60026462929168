import styled from 'styled-components'

export const ConfirmationTitle = styled.h1`
margin: 0;
padding: 0;
width: 100%;
text-align:center;
padding-left:60px;
font-size: 24px;
line-height: 24px;
font-weight: 600;
margin-top:20px;
`

export const ConfirmationMessage = styled.p`
    width:100%;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
`
export const FooterButtonsWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 20px;

    button {
        flex: 1 1 50%;
        font-size: 16px;
    }

`