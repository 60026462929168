import React from 'react';
import { createPortal } from 'react-dom';
import { CustomToast, Toast } from './Toaster.index.jsx';
import styled from 'styled-components';
import { useToast } from './useToast.js';

export const ToastContext = {};

const ToastsContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  padding: 20px;
  z-index: 1000;
`;

export const ToastProvider = ({ domNode }) => {
  const { toasts, checkToasts, removeToast } = useToast();

  return (
    <>
      {createPortal(
        <ToastsContainer>
          {toasts.map(toast => {
            return toast.custom ? (
              <CustomToast
                key={toast.id}
                toast={toast}
                id={toast.id}
                checkToasts={checkToasts}
                custom={toast.custom}
                options={toast.options}
                removeToast={() => removeToast(toast.id)}
              />
            ) : (
              <Toast
                key={toast.id}
                toast={toast}
                id={toast.id}
                checkToasts={checkToasts}
                removeToast={() => removeToast(toast.id)}
                options={toast.options}
              />
            );
          })}
        </ToastsContainer>,
        domNode || document.body
      )}
    </>
  );
};
