import React, { forwardRef, useEffect, useRef } from 'react';
import { Wrapper } from './Tooltip.style';
import PropTypes from 'prop-types';

export const createTooltipWrapper = ({ position }) => {
  let tooltipRef = { current: undefined };
  const options = {
    timeout: {
      id: null
    },
    executeDelay: {
      state: true,
      id: null
    }
  };
  const initialPosition = {
    offsetLeft: 0
  };

  function launchTooltip(targetRef, { type, offset, title, delay = 300 }) {
    if (type === 'leave') {
      onMouseLeave(tooltipRef.current, options);
      return;
    }

    if (type === 'enter') {
      onMouseEnter(tooltipRef.current, delay, options, () => {
        tooltipRef.current.style.pointerEvents = `none`;

        const {
          width: parentWidth,
          height: parentHeight,
          top,
          left
        } = targetRef.current.getBoundingClientRect();

        tooltipRef.current.style.width = `${parentWidth}px`;
        tooltipRef.current.style.height = `${parentHeight}px`;
        tooltipRef.current.style.top = `${top - (offset?.y ?? 0)}px`;
        tooltipRef.current.style.left = `${left}px`;
        tooltipRef.current.dataset.title = title;
      });
    }
  }

  return {
    TooltipWrapper() {
      useEffect(() => {
        initialPosition.offsetLeft = tooltipRef.current.offsetLeft;
      }, []);

      return <Tooltip_ ref={tooltipRef} layout='fixed' position={position} />;
    },
    launchTooltip
  };
};

function Tooltip(
  { children, title, delay = 300, size, position, layout },
  ref
) {
  const myRef = useRef();
  const options = {
    timeout: {
      id: null
    },
    executeDelay: {
      state: true,
      id: null
    }
  };

  let ref_ = null;

  if (ref && new Set(Object.keys(ref)).has('current')) {
    ref_ = ref;
  } else {
    ref_ = myRef;
  }

  const dynamicProps = () => {
    if (children) {
      return {
        size,
        onMouseEnter: () => onMouseEnter(ref_.current, delay, options, null),
        onMouseLeave: () => onMouseLeave(ref_.current, options),
        ['data-title']: title
      };
    } else {
      return {
        layout
      };
    }
  };

  return (
    <Wrapper
      ref={ref_}
      role='tooltip'
      position={position ?? 'default'}
      {...dynamicProps()}
    >
      <span className='arrow-up'></span>
      {children && children}
    </Wrapper>
  );
}

const onMouseEnter = (ref, delay, options, callback) => {
  const { timeout, executeDelay } = options;
  clearTimeout(executeDelay.id);

  const currentId = setTimeout(
    () => {
      if (ref) {
        ref.classList.add('active');
      }

      if (callback) {
        callback();
      }
    },
    executeDelay.state ? delay : 0
  );

  timeout.id = currentId;
};
const onMouseLeave = (ref, options) => {
  const { timeout, executeDelay } = options;
  clearTimeout(timeout.id);

  executeDelay.state = false;

  executeDelay.id = setTimeout(() => {
    executeDelay.state = true;
  }, 1000);

  ref.classList.remove('active');
};

const Tooltip_ = forwardRef(Tooltip);

export default Tooltip_;

Tooltip.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string,
  delay: PropTypes.number,
  size: PropTypes.object
};
