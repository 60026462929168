/* eslint-disable no-unused-vars */
import React, { forwardRef } from 'react';
import { StyledMenu, Item } from '../Multiselect.style';

import Input from '../../Input/Input.index';
import _Input from '../../Inputs/Input/Input.index.jsx';

function Menu(
  { error, inputRef, menuStyle, options, handleChanges, autocomplete },
  ref
) {
  let _options;
  if (!error) {
    _options = options.map((option, index) => {
      /** @param {import('react').ChangeEvent} event*/
      const onChangeHandler = event => {
        const newOption = { ...option, checked: event.target.checked };
        handleChanges(newOption);
      };
      return (
        <Item key={option.key ?? index}>
          <Input
            type='Checkbox'
            title={option.label}
            name={option.label}
            value={option.value}
            checked={option.checked}
            onChange={onChangeHandler}
          />
        </Item>
      );
    });
  }

  const renderView = () => {
    return (
      <>
        {autocomplete && (
          <div style={{ padding: '10px' }}>
            <_Input ref={inputRef} placeholder='Filtrar' />
          </div>
        )}

        {!error && _options}
      </>
    );
  };

  return (
    <StyledMenu ref={ref} style={menuStyle} data-hide='true'>
      {renderView()}
      {error && (
        <div
          style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}
        >
          <p>Nenhum resultado.</p>
        </div>
      )}
    </StyledMenu>
  );
}

const Menu_ = forwardRef(Menu);
export default Menu_;
