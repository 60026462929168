import isString from '../utils/isString';

function checkConditions(
  canProceed,
  fields,
  where,
  incomingWhere,
  incomingFields
) {
  for (const [key, value] of where.entries()) {
    if (value && key === incomingWhere) {
      canProceed = { ...canProceed, where: true };
      break;
    }
  }
  if (isString(fields)) {
    canProceed = {
      ...canProceed,
      field: isString(incomingFields)
        ? incomingFields === fields
        : Boolean(incomingFields._keys.find(v => v === fields))
    };
  } else {
    for (const value of fields) {
      if (
        isString(incomingFields)
          ? value === incomingFields
          : incomingFields._keys.find(v => v === value)
      ) {
        canProceed = { ...canProceed, field: true };
        break;
      }
    }
  }

  return canProceed;
}

export const runWatcher = (field, _where, state) => {
  if (!state._watcher) {
    return;
  }

  if (state._watcher.watchers) {
    const { watchers } = state._watcher;

    watchers.forEach(w => {
      let canProceed = { field: false, where: false };

      canProceed = checkConditions(
        canProceed,
        w.fields,
        w.where,
        _where,
        field
      );

      if (!canProceed.field || !canProceed.where) return;

      w.watch(
        isString(w.fields)
          ? state._values[w.fields]
          : w.fields.reduce(
              (acc, field) => ({ ...acc, [field]: state._values[field] }),
              {}
            ),
        {
          errors: state._errors,
          values: state._values,
          where: _where
        }
      );
    });
  }
};
