import React from "react"
import PropTypes from 'prop-types'
import { NotificationHeaderTitle, NotificationContentTitle } from "./Notification.style"

export default function useNotificationEvent ({payload, setters}) {
    setters.setConfigs(payload?.configs)

    const header = []
    const content = []
    const footer = []

    mountHeader(header, payload)
    mountContent(content, payload)
    mountFooter(footer, payload)

    setters.setHeader(header)
    setters.setContent(content)
    setters.setFooter(footer)
    setters.openModal()
}

function mountHeader (header, payload) {
    const {title: headerTitle} = {...payload.header}
    if(headerTitle) {
        header.push(<NotificationHeaderTitle>{headerTitle}</NotificationHeaderTitle>)
    }
}

function mountContent (content, payload) {
    const {title: contentTitle, body: contentBody} = {...payload.content}
    if(contentTitle) {
        content.push(<NotificationContentTitle>{contentTitle}</NotificationContentTitle>)
    }
    if(Array.isArray(contentBody)) {
        content.push.apply(content, contentBody)
    }
}

function mountFooter (footer, payload) {
    const {body: footerBody} = {...payload.footer}
    if(Array.isArray(footerBody)) {
        footer.push.apply(footer, footerBody)
    }
}


useNotificationEvent.propTypes = {
    payload: PropTypes.arrayOf(
        PropTypes.shape({
            header: PropTypes.shape({title: PropTypes.string}),
            content: PropTypes.shape({
                title: PropTypes.string,
                body: PropTypes.array,
            }),
            footer: PropTypes.shape({
                body: PropTypes.array
            }),
            configs: PropTypes.object
        })
    ).isRequired,
    setters: PropTypes.arrayOf(
        PropTypes.shape({
            openModal: PropTypes.func,
            closeModal: PropTypes.func,
            setHeader: PropTypes.func,
            setContent: PropTypes.func,
            setFooter: PropTypes.func,
            setConfigs: PropTypes.func
        })
    ).isRequired
  }