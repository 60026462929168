class ImprovedObject {
  constructor(cloneObject) {
    if (cloneObject) {
      Object.entries(cloneObject).forEach(
        ([key, value]) => (this[key] = value)
      );
    }
  }
  entries(callback) {
    if (callback instanceof Function) {
      const entries = new Map(Object.entries(this));
      entries.forEach((value, key) => callback(value, key));
    } else {
      return Object.entries(this);
    }
  }
  get _keys() {
    return Object.keys(this);
  }
  get _values() {
    return Object.values(this);
  }
  get _size() {
    return Object.keys(this).length;
  }
}

export { ImprovedObject };
