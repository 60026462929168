import React from "react"
import PropTypes from 'prop-types'
import { InputRadioWrapper } from "./Radio.style"

export default function Radio({id,value,name,title, style={}, ...rest}) {   
    return <InputRadioWrapper style={style}>
        <input type="radio" id={id} value={value} name={name} {...rest} />
        <label htmlFor={id}>{title}</label>
    </InputRadioWrapper> 
}

Radio.propTypes = {
    id: PropTypes.string,
    value: PropTypes.any,
    name: PropTypes.string,
    title: PropTypes.string
}