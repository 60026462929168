import React from 'react';

export default function Paper({ svg, path, ...rest }) {
  return (
    <svg
      width='24'
      height='28'
      viewBox='0 0 24 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...svg}
      {...rest}
    >
      <path
        d='M23.0613 7.94L16.0613 0.94C15.9219 0.800523 15.7565 0.689871 15.5744 0.614373C15.3923 0.538874 15.1971 0.500009 15 0.5H3C2.33696 0.5 1.70107 0.763392 1.23223 1.23223C0.763392 1.70107 0.5 2.33696 0.5 3V25C0.5 25.663 0.763392 26.2989 1.23223 26.7678C1.70107 27.2366 2.33696 27.5 3 27.5H21C21.663 27.5 22.2989 27.2366 22.7678 26.7678C23.2366 26.2989 23.5 25.663 23.5 25V9C23.5 8.60249 23.3422 8.22124 23.0613 7.94ZM16 5.125L18.875 8H16V5.125ZM3.5 24.5V3.5H13V9.5C13 9.89782 13.158 10.2794 13.4393 10.5607C13.7206 10.842 14.1022 11 14.5 11H20.5V24.5H3.5Z'
        fill='#323232'
      />
    </svg>
  );
}
