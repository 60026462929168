import styled from 'styled-components';

export const ListWrapper = styled.article`
  display: grid;
  grid-template-rows: 60px auto 75px;
  width: 100%;
  height: 100%;
  background-color: #fff;
  font-size: 12px;
  color: hsl(221, 13%, 46%);
  border-radius: 0px 0px 8px 8px;
  box-sizing: border-box;

  > * {
    box-sizing: border-box;
  }
`;

export const PaginatorWrapper = styled.article`
  width: 100%;
  height: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
`;

export const ListHeader = styled.header`
  display: flex;
  background-color: hsl(220, 17%, 93%);
  color: hsl(221, 13%, 46%);
  align-items: center;
  padding: 0 10px;
`;
