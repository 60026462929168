import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  gap: 10px;

  ${function ({ disabled }) {
    return (
      disabled &&
      css`
        opacity: 0.5;
      `
    );
  }}
`;

export const SwitchWrapper = styled.label`
  position: relative;
  display: flex;
  width: 40px;
  height: 24px;
`;

export const Label = styled.label`
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  color: #344054;
`;

export const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 400ms;
  border-radius: 100vw;

  &:before {
    position: absolute;
    content: '';
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 300ms;
    border-radius: 100vw;
  }
`;

export const Input = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + ${Slider} {
    background-color: #00bf6f;
  }

  &:disabled + ${Slider} {
    cursor: not-allowed;
  }

  &:focus + ${Slider} {
    box-shadow: 0 0 1px #4caf50;
  }

  &:checked + ${Slider}:before {
    transform: translateX(100%);
  }
`;
