import React from 'react';
import PropTypes from 'prop-types';
import { Frame } from './GridWidget.style';
import * as Widgets from './components/Widgets.index';

export default function GridWidget({ area, widget, configs }) {
  const Widget = Widgets[widget];

  return (
    <Frame area={area}>
      <Widget {...configs} />
    </Frame>
  );
}

GridWidget.propTypes = {
  area: PropTypes.string.isRequired,
  widget: PropTypes.string.isRequired,
  configs: PropTypes.object.isRequired
};
