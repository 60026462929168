import React, { forwardRef } from 'react'
export { EventModalHeader } from './components/EventModalHeader.index.jsx'
export { EventModalContent } from './components/EventModalContent.index.jsx'
export { EventModalFooter } from './components/EventModalFooter.index.jsx'

import {
  ModalOverlay,
  ModalContainer
} from './EventModal.style'

const EventModal = forwardRef(function EventModal({children},ref) {
  return (
    <ModalOverlay ref={ref} style={{display:'none'}}>
      <ModalContainer>
        {children}
      </ModalContainer>
    </ModalOverlay>
  )
})
  
export default EventModal
