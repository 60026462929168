import styled, { css } from 'styled-components';

const getSize = ({ size }) => {
  return css`
    width: ${size === 'auto' ? 'auto' : size.width};
    height: ${size === 'auto' ? 'auto' : size.height};
  `;
};

export const Container = styled.div`
  ${getSize};
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
`;

export const PieChartStyled = styled.svg`
  width: 100%;
  height: 100%;
`;
