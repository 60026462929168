import React from 'react';
export default function CheckMark({ svg, path, ...rest }) {
  return (
    <svg
      width='14'
      height='14'
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...svg}
      {...rest}
    >
      <path
        d='M11.6663 3.5L5.24967 9.91667L2.33301 7'
        stroke='white'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
        {...path}
      />
    </svg>
  );
}
