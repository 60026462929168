export const mergeStyles = (...styles) => {
  let mergedStyle;
  styles.forEach(style => {
    if (typeof style === 'string' || typeof style === 'number') {
      throw new Error('Style recebido inválido');
    }
  });
  styles.forEach(
    style =>
      (mergedStyle = {
        ...mergedStyle,
        ...Object.entries(style)
          .filter(([_, value]) => Boolean(value))
          .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})
      })
  );

  return mergedStyle;
};
