import styled from 'styled-components';

export const PaginatorWrapper = styled.section`
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 0 5px;
  height: 50px;
  overflow-x: auto;
  border-radius: 0px 0px 8px 8px;
`;

export const PaginatorButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  color: hsl(221, 13%, 46%);
  width: 35px;
  height: 40px;
  margin: 0 2px;
  cursor: pointer;
  font-size: 14px;
  border: none;
  outline: none;
  background: transparent;

  &.button-style-1 {
    width: auto;
    display: flex;
    gap: 8px;
  }

  ${({ currentPage, page }) =>
    currentPage === page &&
    typeof currentPage !== 'undefined' &&
    typeof page !== 'undefined'
      ? `
            color: #fff;
            background-color: #00BF6F;
            border-radius: 5px;
        `
      : ''};
`;
