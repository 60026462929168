const elementsSizes = {
  wrapper: {
    withLegend: {
      width: '250',
      height: '100',
      viewPort: '0 0 125 75'
    },
    noLegend: {
      width: '50',
      height: '50',
      viewPort: '0 0 50 50'
    },
    dynamic: () => {
      return {
        width: '250',
        height: '100',
        viewPort: '0 0 125 75'
      };
    }
  },
  circles: {
    noLegend: ({ color, percentage, data }) => {
      return {
        r: '12.5',
        cx: '50%',
        cy: '50%',
        color: color,
        percentage: percentage,
        transform: 'rotate(-90, 25, 25)'
      };
    },
    dynamic: values => {
      const {
        color,
        percentage,
        DATA_LENGTH_IS_ABOVE_SIX,
        largestFirstGroupText,
        largestLastGroupText
      } = values;

      if (DATA_LENGTH_IS_ABOVE_SIX) {
        return {
          r: '18',
          cx: '12%',
          cy:
            largestFirstGroupText.length > 10 ||
            largestLastGroupText.length > 10
              ? '20%'
              : '50%',
          color: color,
          percentage: percentage,
          transform: 'rotate(-90, 25, 25)'
        };
      } else {
        return {
          r: '18',
          cx: '12%',
          cy: '50%',
          color: color,
          percentage: percentage,
          transform: 'rotate(-90, 25, 25)'
        };
      }
    }
  },
  legendCircle: {
    dynamic: values => {
      const { index, color, cx, radius } = values;
      return {
        r: radius,
        cy: `${(35 * index) / 2.7}%`,
        cx,
        fill: color,
        transform: 'translate(0,0)'
      };
    }
  },

  legendText: {
    dynamic: values => {
      const { index, increment, x, fontSize } = values;
      return {
        fontSize,
        fontFamily: 'Poppins',
        fontWeight: 500,
        x,
        y: `${(35 * (index + increment)) / 2.7}%`
      };
    }
  }
};

const getSize = (element, size) => {
  return elementsSizes[element][size];
};

export { getSize };
