import styled, { css } from 'styled-components';
import { ScrollbarStyle } from '../../styles/scrollbar';

export const Wrapper = styled.section`
  position: relative;
  width: auto;
  margin: 0;
  display: initial;
  ${({ hideAndShowLoader }) =>
    hideAndShowLoader
      ? css`
          display: none;
        `
      : css`
          display: initial;
        `}

  > * {
    box-sizing: border-box;
  }
`;

export const StyledMenu = styled.ul`
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  background: white;
  border-radius: 10px;
  border: ${({ openMenu, showError }) =>
    openMenu || showError ? '1px solid #e7e7e7;' : 'none'};
  width: 100%;
  height: ${({ openMenu, showError, showLoader }) => {
    if (openMenu || showLoader) return `auto`;
    if (showError) return `120px`;
    return 0;
  }};
  max-height: 200px;
  padding: ${({ showError }) => (showError ? '10px' : 0)};
  list-style: none;
  overflow-y: ${({ showLoader }) => (showLoader ? 'none' : 'auto')};
  z-index: 10;
  margin: 0;

  /* box-shadow: 0px 0px 23px -4px rgba(0, 0, 0, 0.1); */

  &[data-hide='true'] {
    display: none;
  }

  ${ScrollbarStyle}
`;

export const Item = styled.li`
  color: #555555;
  font-size: 14px;
  padding: 13px;
  display: flex;
  position: relative;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;

  ${({ selected }) =>
    selected &&
    css`
      background: rgba(0, 191, 111, 0.12);
      color: rgba(0, 191, 111, 1);
    `}

  &[data-hide-item='true'] {
    display: none;
  }

  &:hover {
    ${({ selected }) =>
      selected
        ? css`
            background: rgba(0, 191, 111, 0.22);
          `
        : css`
            background: #f9fafb;
          `}
  }

  /* ~ ::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    left: 50%;
    transform: translateX(-50%);
    background: #f4f4f4;
  } */
`;

export const StyledError = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: 10px;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  position: relative;

  &[data-hide-error='true'] {
    display: none;
  }

  h1 {
    font-size: 14px;
  }
`;

export const StyledDefaultLoading = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
`;

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  ${({ disabled }) =>
    disabled
      ? css`
          opacity: 0.5;
        `
      : css`
          opacity: 1;
        `}
`;

export const ChevronWrapper = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  display: flex;
  cursor: pointer;
  background: none;
  outline: none;
  border: none;

  svg {
    pointer-events: none;
  }

  &[data-up='true'] {
    > svg {
      transform: rotate(180deg);
    }
  }
`;
