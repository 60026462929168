import styled from 'styled-components'

export const NotificationHeaderTitle = styled.h1`
  margin: 0;
  padding: 0;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  align-self: flex-start;
`

export const NotificationContentTitle = styled.h1`
  margin: 0;
  padding: 0;
  font-size: 24px;
  line-height: 36px;
  font-weight: 600;
`