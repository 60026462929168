import React from 'react';

export default function Data({ svg, path, ...rest }) {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...svg}
      {...rest}
    >
      <path
        d='M19.3516 9.375H10.625V0.647656C10.625 0.295117 10.8633 0 11.25 0C16.082 0 20 3.91797 20 8.75C20 9.10156 19.7031 9.375 19.3516 9.375ZM8.75 11.25H17.3945C18.1172 11.25 18.6953 11.8633 18.5469 12.5703C17.6523 16.8164 13.8867 20 9.375 20C4.19922 20 0 15.8047 0 10.625C0 6.11328 3.18477 2.34844 7.42969 1.45234C8.13672 1.30313 8.75 1.88281 8.75 2.60547V11.25ZM6.875 3.55117C3.96094 4.58203 1.875 7.35938 1.875 10.625C1.875 14.7656 5.19922 18.125 9.375 18.125C12.6406 18.125 15.418 16.0391 16.4492 13.125H6.875V3.55117Z'
        fill='#323232'
        {...path}
      />
    </svg>
  );
}
