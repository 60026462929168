import React, { forwardRef, useState } from 'react'
import useRenderChildrens from '../logic/hooks/useRenderChildrens'
import { useIcon } from '../../../../../components/Icon/logic/hooks/useIcon'

import {
  ModalHeader,
  CircleClose
} from '../EventModal.style'

export const EventModalHeader = forwardRef(function EventModalHeader({handleClose, childrens},ref) {
  const [CloseIcon] = useIcon('close')
  const [displayClose, setDisplayClose] = useState(true)
  const childrensRef = useRenderChildrens({childrens, ref})
  ref.current.setDisplayClose = setDisplayClose
  return (
    <ModalHeader>
      {childrensRef.current}
      <CircleClose 
        onClick={handleClose}
        style={{display: displayClose ? 'flex' : 'none'}}
      >
        <CloseIcon 
        path={{stroke: 'hsl(0,0%,53%)'}}/>
      </CircleClose>
    </ModalHeader>
  )
})