import React, { useMemo } from 'react';
import {
  ButtonsContainer,
  Logo,
  MainContainer,
  MainView,
  OptionsView
} from './Sidebar.style';
import Icon from './components/Icon/Icon.index';
import {
  PrimaryButton,
  ProfileButton
} from './components/Buttons/Buttons.index';
import useOptionViewVisibility from './logic/hooks/useOptionViewVisibility';
import useSidebarConfig, {
  optionsViewlist
} from './logic/hooks/useSidebarConfig';

import { createTooltipWrapper } from '../Tooltip/Tooltip.index.jsx';

const renderButtons = () => {
  let buttons = [];
  const { TooltipWrapper, launchTooltip } = useMemo(
    () =>
      createTooltipWrapper({
        position: 'right'
      }),
    []
  );

  const variations = {
    user: value => (
      <ProfileButton
        key='user'
        keyValue='user'
        title='Usuário'
        onClick={value.onClick}
        launchTooltip={launchTooltip}
        imageSource={value.imageSource}
      />
    )
  };
  const icons = {
    home: 'home',
    arquivos: 'archives',
    layouts: 'stack',
    demands: 'demands',
    ferramentas: 'tool',
    cadastros: 'addSquare',
    notificacoes: 'notification',
    configuracoes: 'config',
    monday: 'monday'
  };

  const titles = {
    home: 'Dashboard',
    arquivos: 'Arquivos',
    layouts: 'Layouts',
    demands: 'Suporte',
    ferramentas: 'Ferramentas',
    cadastros: 'Cadastros',
    notificacoes: 'Notificações',
    configuracoes: 'Configurações',
    monday: 'Gestão Contábil'
  };

  for (const [key, value] of optionsViewlist) {
    buttons = [
      ...buttons,
      variations[key]?.(value) ?? (
        <PrimaryButton
          icon={icons[key]}
          key={key}
          keyValue={key}
          title={titles[key]}
          launchTooltip={launchTooltip}
          onClick={value.onClick}
        />
      )
    ];
  }

  return (
    <>
      <div className='top'>{buttons.slice(0, buttons.length - 1)}</div>
      <div className='end'>{buttons.slice(buttons.length - 1)}</div>
      <TooltipWrapper />
    </>
  );
};

const MainComponent = ({ config, user }) => {
  const { open } = useOptionViewVisibility();
  const { optionsView } = useSidebarConfig({ config, user });

  return (
    <>
      <MainView>
        <Logo>
          <Icon name='plick' />
        </Logo>
        <ButtonsContainer>{renderButtons()}</ButtonsContainer>
      </MainView>
      <OptionsView visible={open}>{optionsView}</OptionsView>
    </>
  );
};

function Sidebar({ config, user }) {
  return (
    <MainContainer>
      <MainComponent config={config} user={user} />
    </MainContainer>
  );
}

export default Sidebar;
