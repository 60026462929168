import React, { forwardRef, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  StyledInput,
  ErrorMessage,
  Label
} from '../../Inputs/Input/Input.style';
import { InputContainer, InputWrapper } from '../styles';

function Input(
  {
    defaultValue,
    style,
    name,
    handleInitialValue,
    deps,
    freezeOnMount,
    error,
    disabled,
    label,
    Chevron,
    ...rest
  },
  ref
) {
  const isMounted = useRef(false);
  const freeze = useRef(freezeOnMount);

  const _style = {
    ...style,
    paddingRight: '40px'
  };

  useEffect(() => {
    if (
      (defaultValue || ref.current.value) &&
      !isMounted.current &&
      !freeze.current
    ) {
      handleInitialValue(defaultValue || ref.current.value);
    } else if (!freeze.current) {
      handleInitialValue(null);
    }

    freeze.current = false;
    isMounted.current = true;
  }, [...deps]);

  return (
    <InputContainer disabled={disabled}>
      {label && <Label htmlFor={name}>{label}</Label>}
      <InputWrapper>
        <StyledInput
          ref={ref}
          style={_style}
          error={error}
          disabled={disabled}
          type='text'
          {...rest}
        />
        {Chevron}
      </InputWrapper>
      {error && error?.message && <ErrorMessage>{error.message}</ErrorMessage>}
    </InputContainer>
  );
}

export default forwardRef(Input);

Input.propTypes = {
  ref: PropTypes.any,
  handleInitialValue: PropTypes.func,
  deps: PropTypes.array,
  freezeOnMount: PropTypes.bool,
  style: PropTypes.object,
  name: PropTypes.string,
  startSearch: PropTypes.func,
  delay: PropTypes.number
};
