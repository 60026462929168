import styled, { css } from 'styled-components';
import { ScrollbarStyle } from '../../styles/scrollbar';

export const Wrapper = styled.section`
  position: relative;
  width: auto;
  margin: 0;
  flex: auto;

  ${({ hide }) =>
    hide &&
    css`
      display: none;
    `}
`;

export const StyledMenu = styled.ul`
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  background: white;
  border-radius: 10px;
  border: 1px solid #e7e7e7;
  width: 100%;
  height: auto;
  max-height: 200px;
  padding: 0;
  list-style: none;
  overflow-y: auto;
  z-index: 10;
  margin: 0;
  /* box-shadow: 0px 0px 23px -4px rgba(0, 0, 0, 0.1); */

  &[data-hide='true'] {
    display: none;
  }

  ${ScrollbarStyle}
`;

export const Item = styled.li`
  color: #555555;
  font-size: 14px;
  padding: 13px;
  display: flex;
  position: relative;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}

  &[data-hide-item='true'] {
    display: none;
  }

  :hover {
    background: #f9fafb;
  }

  ~ ::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 95%;
    height: 1px;
    left: 50%;
    transform: translateX(-50%);
    background: #f4f4f4;
  }
`;

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  ${({ disabled }) =>
    disabled
      ? css`
          opacity: 0.5;
        `
      : css`
          opacity: 1;
        `}
`;

export const ChevronWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  display: flex;

  &[data-up='true'] {
    > svg {
      transform: rotate(180deg);
    }
  }
`;
