import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { InputCheckboxWrapper } from './Checkbox.style';
import { getIcon } from '../../../Icon/getIcon';

const CheckMark = getIcon('checkMark');
function Checkbox({ id, error, value, name, title, style = {}, ...rest }, ref) {
  return (
    <InputCheckboxWrapper error={error}>
      <input
        ref={ref}
        type='checkbox'
        id={id}
        value={value}
        name={name}
        {...rest}
      />
      <span className='checkmark'>
        <CheckMark />
      </span>
      <span className='title'>{title}</span>
    </InputCheckboxWrapper>
  );
}

export default forwardRef(Checkbox);

Checkbox.propTypes = {
  id: PropTypes.string,
  value: PropTypes.any,
  name: PropTypes.string,
  title: PropTypes.string
};
