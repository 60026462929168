import React from 'react';
import PropTypes from 'prop-types';
import {
  ListContentWrapper,
  ListContentHeaders,
  ListContentRow
} from './ListContent.style';

import stringHelpers from '../../../../../../helpers/stringHelpers';

export default function ListContent({ list }) {
  return (
    <ListContentWrapper>
      <ListContentHeaders>
        {list.listHeaders.map((headerItem, key) => {
          return (
            <div
              key={`widget-list-header-${key}`}
              title={headerItem.alias.toString()}
            >
              {stringHelpers.treatString(
                headerItem.alias.toString(),
                'textLimiter',
                { limit: 14 }
              )}
            </div>
          );
        })}
      </ListContentHeaders>
      {list.listRows.map((rowItem, keyRow) => (
        <ListContentRow
          key={`widget-list-row-${keyRow}`}
          onDoubleClick={rowItem.onClick}
        >
          {list.listHeaders.map((headerItem, keyHeader) => (
            <ListContentRowItem
              key={`widget-list-row-item-${keyRow}-${keyHeader}`}
              rowItem={rowItem}
              field={headerItem.field}
            />
          ))}
        </ListContentRow>
      ))}
    </ListContentWrapper>
  );
}

ListContent.propTypes = {
  list: PropTypes.shape({
    listHeaders: PropTypes.arrayOf(
      PropTypes.shape({
        field: PropTypes.string.isRequired,
        alias: PropTypes.string.isRequired
      })
    ).isRequired,
    listRows: PropTypes.arrayOf(
      PropTypes.shape({
        data: PropTypes.object,
        onClick: PropTypes.func,
        custom: PropTypes.arrayOf(
          PropTypes.shape({
            field: PropTypes.string,
            content: PropTypes.element
          })
        )
      })
    ).isRequired
  })
};

function ListContentRowItem({ rowItem, field }) {
  if (rowItem?.custom) {
    const customIdx = rowItem.custom.map(custom => custom.field).indexOf(field);
    if (customIdx !== -1) {
      return <div>{rowItem.custom[customIdx].content}</div>;
    }
  }

  return (
    <div>
      <span title={rowItem.data[field].toString()}>
        {stringHelpers.treatString(
          rowItem.data[field].toString(),
          'textLimiter',
          { limit: 14 }
        )}
      </span>
    </div>
  );
}

ListContentRowItem.propTypes = {
  rowItem: PropTypes.object,
  field: PropTypes.string
};
