import styled from 'styled-components'

export const SuccessHeaderTitle = styled.h1`
  margin: 0;
  padding: 0;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  align-self: flex-start;
  margin-top:12px;
`

export const SuccessContainer = styled.div`
`
export const SuccessMessage = styled.p`
`

export const SuccessFooter = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
`
