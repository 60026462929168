import styled, { css } from 'styled-components';

function renderStyle({ noStyle }) {
  if (noStyle) {
    return css`
      background: transparent;
      border: none;
    `;
  }
  return css`
    background: white;
    border: 1px solid #d0d5dd;
  `;
}

export const Container = styled.button`
  cursor: pointer;
  outline: none;
  color: #000;
  font-weight: 500;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  ${renderStyle}
  border-radius: 8px;
  display: flex;
  padding: 10px 18px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  ${({ size }) => size};

  :disabled {
    opacity: 0.4;
  }

  > svg {
    flex: 0 0 auto;
  }

  > span {
    flex: 0 1 auto;
  }
`;
