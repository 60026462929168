import React, { useContext, createContext } from 'react';
import PropTypes from 'prop-types';
import { eventBus } from '../services/EventBus/eventBus.index.js';
import useEventModal from '../components/EventModal/logic/hooks/EventsHooks/useEventModal.js';
import useEventLoader from '../components/EventLoader/logic/hooks/useEventLoader';

window.eventsList = [];
window.eventBus = eventBus;

const EventContext = createContext({});

export const useEventContext = () => {
  return useContext(EventContext);
};

function EventProvider({ children }) {
  const { eventLoaderSetters, eventLoaderComponent } = useEventLoader();

  const { eventModalSetters, eventModalComponent } = useEventModal();

  return (
    <EventContext.Provider
      value={{
        eventLoader: eventLoaderSetters,
        eventModal: eventModalSetters
      }}
    >
      {children}

      {eventModalComponent}

      {/* EventToast */}

      {eventLoaderComponent}
    </EventContext.Provider>
  );
}

EventProvider.propTypes = {
  children: PropTypes.element.isRequired
};

export default EventProvider;
