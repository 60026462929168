import React from 'react';

export default function TxtIconExtension({ svg, path, ...rest }) {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      {...svg}
      {...rest}
    >
      <path
        d='M9.5 17.5H4.5C4.10218 17.5 3.72064 17.658 3.43934 17.9393C3.15804 18.2206 3 18.6022 3 19C3 19.3978 3.15804 19.7794 3.43934 20.0607C3.72064 20.342 4.10218 20.5 4.5 20.5H5.5V26C5.5 26.3978 5.65804 26.7794 5.93934 27.0607C6.22064 27.342 6.60218 27.5 7 27.5C7.39782 27.5 7.77936 27.342 8.06066 27.0607C8.34196 26.7794 8.5 26.3978 8.5 26V20.5H9.5C9.89782 20.5 10.2794 20.342 10.5607 20.0607C10.842 19.7794 11 19.3978 11 19C11 18.6022 10.842 18.2206 10.5607 17.9393C10.2794 17.658 9.89782 17.5 9.5 17.5ZM4.5 13.5V5C4.5 4.33696 4.76339 3.70107 5.23223 3.23223C5.70107 2.76339 6.33696 2.5 7 2.5H19C19.197 2.49985 19.3922 2.53851 19.5743 2.6138C19.7564 2.68908 19.9218 2.7995 20.0613 2.93875L27.0613 9.93875C27.2005 10.0782 27.3109 10.2436 27.3862 10.4257C27.4615 10.6078 27.5002 10.803 27.5 11V13.5C27.5 13.8978 27.342 14.2794 27.0607 14.5607C26.7794 14.842 26.3978 15 26 15C25.6022 15 25.2206 14.842 24.9393 14.5607C24.658 14.2794 24.5 13.8978 24.5 13.5V13H18.5C18.1022 13 17.7206 12.842 17.4393 12.5607C17.158 12.2794 17 11.8978 17 11.5V5.5H7.5V13.5C7.5 13.8978 7.34196 14.2794 7.06066 14.5607C6.77936 14.842 6.39782 15 6 15C5.60218 15 5.22064 14.842 4.93934 14.5607C4.65804 14.2794 4.5 13.8978 4.5 13.5ZM20 10H22.875L20 7.125V10Z'
        fill='#323232'
        {...path}
      />
      <path
        d='M19.9807 25.6769C19.9359 25.4848 19.8537 25.3035 19.7388 25.1432L17.8638 22.5182L19.7388 19.8932C19.9709 19.5694 20.0648 19.1668 20 18.7738C19.9352 18.3808 19.7169 18.0296 19.3932 17.7975C19.0694 17.5655 18.6668 17.4715 18.2738 17.5363C17.8808 17.6012 17.5296 17.8194 17.2975 18.1432L16.0188 19.9357L14.7388 18.1432C14.5067 17.8194 14.1556 17.6012 13.7626 17.5363C13.3696 17.4715 12.9669 17.5655 12.6432 17.7975C12.3194 18.0296 12.1012 18.3808 12.0363 18.7738C11.9715 19.1668 12.0655 19.5694 12.2975 19.8932L14.1725 22.5182L12.2975 25.1432C12.1826 25.3035 12.1004 25.4848 12.0556 25.6769C12.0108 25.869 12.0042 26.068 12.0363 26.2626C12.0684 26.4572 12.1385 26.6435 12.2427 26.811C12.3468 26.9785 12.4829 27.1239 12.6432 27.2388C12.8035 27.3537 12.9848 27.4359 13.1769 27.4807C13.369 27.5255 13.568 27.5321 13.7626 27.5C13.9572 27.4679 14.1435 27.3978 14.311 27.2937C14.4785 27.1896 14.6239 27.0535 14.7388 26.8932L16.0188 25.0957L17.2975 26.8932C17.4125 27.0535 17.5578 27.1896 17.7253 27.2937C17.8928 27.3978 18.0792 27.4679 18.2738 27.5C18.4684 27.5321 18.6674 27.5255 18.8595 27.4807C19.0515 27.4359 19.2329 27.3537 19.3932 27.2388C19.5535 27.1239 19.6896 26.9785 19.7937 26.811C19.8978 26.6435 19.9679 26.4572 20 26.2626C20.0321 26.068 20.0255 25.869 19.9807 25.6769Z'
        fill='#323232'
        {...path}
      />
      <path
        d='M22.5 17.5H27.5C27.8978 17.5 28.2794 17.658 28.5607 17.9393C28.842 18.2206 29 18.6022 29 19C29 19.3978 28.842 19.7794 28.5607 20.0607C28.2794 20.342 27.8978 20.5 27.5 20.5H26.5V26C26.5 26.3978 26.342 26.7794 26.0607 27.0607C25.7794 27.342 25.3978 27.5 25 27.5C24.6022 27.5 24.2206 27.342 23.9393 27.0607C23.658 26.7794 23.5 26.3978 23.5 26V20.5H22.5C22.1022 20.5 21.7206 20.342 21.4393 20.0607C21.158 19.7794 21 19.3978 21 19C21 18.6022 21.158 18.2206 21.4393 17.9393C21.7206 17.658 22.1022 17.5 22.5 17.5Z'
        fill='#323232'
        {...path}
      />
    </svg>
  );
}
