import Add from './AddIcon/Add.index.jsx';
import Filter from './FilterIcon/Filter.index.jsx';
import Close from './CloseIcon/Close.index.jsx';
import CheckMark from './CheckMarkIcon/CheckMark.index.jsx';
import Delete from './DeleteIcon/Delete.index.jsx';
import Edit from './EditIcon/Edit.index.jsx';
import Search from './SearchIcon/Search.index.jsx';
import Chevron from './ChevronIcon/Chevron.index.jsx';
import Kebab from './Kebab/Kebab.index.jsx';
import Papers from './Papers/Papers.index.jsx';
import Paper from './Paper/Paper.index.jsx';
import Data from './Data/Data.index.jsx';
import Config from './Config/Config.index.jsx';
import XlsExtension from './Extensions/XlsIconExtension.index.jsx';
import CsvExtension from './Extensions/CsvIconExtension.index.jsx';
import PdfExtension from './Extensions/PdfIconExtension.index.jsx';
import OfxExtension from './Extensions/OfxIconExtension.index.jsx';
import XlsxExtension from './Extensions/XlsxIconExtension.index.jsx';
import TxtExtension from './Extensions/TxtIconExtension.index.jsx';
import OdsExtension from './Extensions/OdsIconExtension.index.jsx';
import ClockWise from './ClockWise/ClockWise.index.jsx';
import Upload from './UploadIcon/UploadIcon.index.jsx';
import PlugsConnected from './PlugsConnected/PlugsConnected.index.jsx';
import RefreshIcon from './RefreshIcon/RefreshIcon.index.jsx';
import DownloadIcon from './DownloadIcon/DownloadIcon.index.jsx';
import EyesClosed from './Eyes/Eyes.closed.jsx';
import EyesOpened from './Eyes/Eyes.opened.jsx';
import Messages from './MessagesIcon/MessagesIcon.index.jsx';
import IdentificationCard from './IdentificationCard/IdentificationCard.index.jsx';
import Play from './PlayIcon/Play.index.jsx';
import Stack from './StackIcon/StackIcon.index.jsx';

export const ICONS = {
  add: Add,
  filter: Filter,
  close: Close,
  checkMark: CheckMark,
  delete: Delete,
  edit: Edit,
  search: Search,
  chevron: Chevron,
  kebab: Kebab,
  papers: Papers,
  paper: Paper,
  data: Data,
  config: Config,
  xlsExtension: XlsExtension,
  csvExtension: CsvExtension,
  pdfExtension: PdfExtension,
  ofxExtension: OfxExtension,
  xlsxExtension: XlsxExtension,
  txtExtension: TxtExtension,
  odsExtension: OdsExtension,
  clockWise: ClockWise,
  upload: Upload,
  plugsConnected: PlugsConnected,
  refresh: RefreshIcon,
  download: DownloadIcon,
  eyesOpened: EyesOpened,
  eyesClosed: EyesClosed,
  messages: Messages,
  identificationCard: IdentificationCard,
  play: Play,
  stack: Stack
};

// test for new version
