import React, { useEffect, useRef, useState } from 'react';
import setOptionsView from '../../logic/utils/sidebar/setOptionsView';
import setSidebarVisible from '../../logic/utils/sidebar/setSidebarVisible';
import Icon from '../Icon/Icon.index';
import { Container, ProfileContainer } from './Buttons.style';

export let mappedButtons = new Map();

const useButtonState = initialValue => {
  const [state, setState] = useState(
    initialValue ?? {
      currentStyle: 'initial',
      iconStyle: {}
    }
  );

  return [state, setState];
};

export const ProfileButton = ({
  keyValue,
  onClick,
  imageSource,
  launchTooltip,
  title
}) => {
  const [state, setState] = useButtonState();

  const buttonRef = useRef();
  const allowedClicks = new Map([
    [
      'open-options',
      (actualButton, currentStyle) => {
        switch (currentStyle) {
          case 'initial':
            setSidebarVisible(true);
            setOptionsView(keyValue);
            actualButton.setState({
              currentStyle: 'active',
              iconStyle: {}
            });
            break;
          case 'active':
            setSidebarVisible(false);
            actualButton.setState({
              currentStyle: 'initial',
              iconStyle: {}
            });
            break;
        }
      }
    ]
  ]);
  const handleClick = () => {
    if (onClick === null || onClick === undefined) {
      return;
    }

    mappedButtons.forEach((value, index) => {
      if (value.state.currentStyle === 'active') {
        value.setState({
          currentStyle: 'initial',
          iconStyle: { path: { stroke: '#fff' } }
        });
      }
    });

    if (onClick instanceof Function) {
      onClick();
      setSidebarVisible(false);
      return;
    }

    const actualButton = mappedButtons.get(keyValue);
    const currentStyle = actualButton.state.currentStyle;

    allowedClicks.get(onClick)(actualButton, currentStyle);
  };

  useEffect(() => {
    mappedButtons.set(keyValue, { domNodeRef: buttonRef, state, setState });
  }, [state]);

  return (
    <ProfileContainer
      onMouseEnter={() => {
        launchTooltip(buttonRef, {
          type: 'enter',
          offset: {
            y: 15
          },
          title,
          delay: 700
        });
      }}
      onMouseLeave={() => {
        launchTooltip(buttonRef, {
          type: 'leave'
        });
      }}
      ref={buttonRef}
      onClick={handleClick}
    >
      <img src={imageSource} alt='profile' />
    </ProfileContainer>
  );
};

export const PrimaryButton = ({
  icon,
  keyValue,
  onClick,
  launchTooltip,
  title
}) => {
  const [state, setState] = useButtonState({
    currentStyle: 'initial',
    iconStyle: { path: { stroke: '#fff' } }
  });

  const buttonRef = useRef();
  const allowedClicks = new Map([
    [
      'open-options',
      (actualButton, currentStyle) => {
        switch (currentStyle) {
          case 'initial':
            setSidebarVisible(true);
            setOptionsView(keyValue);
            actualButton.setState({
              currentStyle: 'active',
              iconStyle: { path: { stroke: '#000' } }
            });
            break;
          case 'active':
            setSidebarVisible(false);
            actualButton.setState({
              currentStyle: 'initial',
              iconStyle: { path: { stroke: '#fff' } }
            });
            break;
        }
      }
    ]
  ]);

  const handleClick = (actualButton, currentStyle) => {
    if (onClick instanceof Function) {
      onClick();
      setSidebarVisible(false);
      return;
    }

    allowedClicks.get(onClick)(actualButton, currentStyle);
  };

  useEffect(() => {
    mappedButtons.set(keyValue, { domNodeRef: buttonRef, state, setState });
  }, [state]);

  return (
    <Container
      ref={buttonRef}
      state={state}
      onMouseEnter={() => {
        launchTooltip(buttonRef, {
          type: 'enter',
          offset: {
            y: 15
          },
          title,
          delay: 700
        });
      }}
      onMouseLeave={() => {
        launchTooltip(buttonRef, {
          type: 'leave'
        });
      }}
      onClick={() => {
        if (onClick === null || onClick === undefined) {
          return;
        }

        mappedButtons.forEach((value, index) => {
          if (value.state.currentStyle === 'active') {
            value.setState({
              currentStyle: 'initial',
              iconStyle: { path: { stroke: '#fff' } }
            });
          }
        });

        const actualButton = mappedButtons.get(keyValue);
        const currentStyle = actualButton.state.currentStyle;

        handleClick(actualButton, currentStyle);
      }}
    >
      <Icon name={icon} styles={state.iconStyle} />
    </Container>
  );
};
