const eventBus = {
    async on(event, callback) {
      if (!window.eventsList.find((eventItem) => eventItem.event === event)) {
        document.addEventListener(event, async (e) => callback(e.detail))
        window.eventsList.push({
          id: window.eventsList.length + 1,
          event: event,
          callback: callback
        })
      }
    },
    dispatch(event, data) {
      document.dispatchEvent(new CustomEvent(event, { detail: data }))
    },
    remove(event, callback) {
      if (typeof callback !== 'undefined') {
        document.removeEventListener(event, callback, false)
      }
      window.eventsList = window.eventsList.filter((eventItem) => {
        if (eventItem.event === event) {
          document.removeEventListener(eventItem.event, eventItem.callback, false)
        }
        return eventItem.event !== event
      })
    }
  }
  
  export { eventBus }
  