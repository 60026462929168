import React from 'react';
import PropTypes from 'prop-types';
import { PaginatorWrapper, PaginatorButton } from './Paginator.style';

export default function Paginator({
  currentPage,
  numberOfPages,
  callbackChangePage
}) {
  const changePage = (action, page) => {
    switch (action) {
      case 'goto':
        callbackChangePage(parseInt(page));
        break;
      case 'goback':
        callbackChangePage(
          parseInt(currentPage) > 1 ? parseInt(currentPage) - 1 : 1
        );
        break;
      case 'goforward':
        callbackChangePage(
          parseInt(currentPage) < parseInt(numberOfPages)
            ? parseInt(currentPage) + 1
            : numberOfPages
        );
        break;
    }
  };

  const getPages = () => {
    if (numberOfPages <= 6) {
      return new Array(numberOfPages).fill(0).map((v, i) => i + 1);
    }

    let arrPages = new Array(numberOfPages).fill(0).map((v, i) => i + 1);

    let initialDelimiter = {
      beginning: currentPage - 1,
      end: currentPage + 2
    };

    if (
      currentPage > arrPages.length - 5 &&
      currentPage >= arrPages.length - 6
    ) {
      initialDelimiter.beginning = arrPages.length - 6;
      initialDelimiter.end = arrPages.length - 3;
    }

    const pages = {
      initial: arrPages.slice(initialDelimiter.beginning, initialDelimiter.end),
      end: arrPages.slice(arrPages.length - 3),
      middle: arrPages.slice(3, arrPages.length - 3)
    };

    return pages;
  };

  const pages = getPages();

  return (
    <PaginatorWrapper>
      {numberOfPages > 0 ? (
        <>
          <PaginatorButton
            style={{
              marginRight: '7px'
            }}
            className='button-style-1'
            onClick={() => changePage('goback')}
          >
            <svg
              width='14'
              height='14'
              viewBox='0 0 14 14'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M12.8333 7H1.16667M1.16667 7L7 12.8333M1.16667 7L7 1.16667'
                stroke='hsl(221, 13%, 46%)'
                strokeWidth='1.67'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </PaginatorButton>

          {numberOfPages <= 6 ? (
            pages.map((page, index) => (
              <PaginatorButton
                currentPage={currentPage}
                page={page}
                onClick={() => changePage('goto', parseInt(page))}
                key={`btn-page${index}`}
              >
                {page}
              </PaginatorButton>
            ))
          ) : (
            <>
              {pages.initial.map((page, index) => (
                <PaginatorButton
                  currentPage={currentPage}
                  page={page}
                  onClick={() => changePage('goto', parseInt(page))}
                  key={`btn-page${index}`}
                >
                  {page}
                </PaginatorButton>
              ))}
              <div>. . .</div>
              {pages.end.map((page, index) => (
                <PaginatorButton
                  currentPage={currentPage}
                  page={page}
                  onClick={() => changePage('goto', parseInt(page))}
                  key={`btn-page${index}`}
                >
                  {page}
                </PaginatorButton>
              ))}
            </>
          )}

          <PaginatorButton
            style={{
              marginLeft: '7px'
            }}
            className='button-style-1'
            onClick={() => changePage('goforward')}
          >
            <svg
              width='14'
              height='14'
              viewBox='0 0 14 14'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M1.16667 7H12.8333M12.8333 7L7 1.16667M12.8333 7L7 12.8333'
                stroke='hsl(221, 13%, 46%)'
                strokeWidth='1.67'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </PaginatorButton>
        </>
      ) : (
        <></>
      )}
    </PaginatorWrapper>
  );
}

Paginator.propTypes = {
  currentPage: PropTypes.number.isRequired,
  numberOfPages: PropTypes.number.isRequired,
  callbackChangePage: PropTypes.func.isRequired
};
