import React, { useRef } from 'react'
import { useEventBus } from '../../../../../services/EventBus/hooks/useEventBus'
import useNotificationEvent from './Notification/useNotificationEvent'
import useConfirmationEvent from './Confirmation/useConfirmationEvent'
import useSuccessEvent from './Success/useSuccessEvent'
import useErrorEvent from './Error/useErrorEvent'

import EventModal, {EventModalHeader, EventModalContent, EventModalFooter} from "../../../EventModal.index.jsx";

export default function useEventModal() {
    const eventModalRef = useRef()
    const eventModalHeaderRef = useRef()
    const eventModalContentRef = useRef()
    const eventModalFooterRef = useRef()

    const eventModalSetters = {
        openModal: () => {
            if (eventModalRef.current?.style) {
                eventModalRef.current.style.display = 'grid'
            }
        },
        closeModal: () => {
            eventModalHeaderRef.current.render([])
            eventModalContentRef.current.render([])
            eventModalFooterRef.current.render([])
            if (eventModalRef.current?.style) {
                eventModalRef.current.style.display = 'none'
            }
        },
        setHeader: (childrens) => {
            eventModalHeaderRef.current.render(childrens)
        },
        setContent: (childrens) => {
            eventModalContentRef.current.render(childrens)
        },
        setFooter: (childrens) => {
            eventModalFooterRef.current.render(childrens)
        },
        setConfigs: (configs) => {
            if(configs) {
                if(configs.header?.hideCloseButton) {
                    eventModalHeaderRef.current.setDisplayClose(false)    
                } else {
                    eventModalHeaderRef.current.setDisplayClose(true)    
                }
            }
        }
    }

    const eventsList = [
        {
            event: 'event-modal-notification', 
            callback: (payload) => {
                useNotificationEvent({payload, setters:eventModalSetters})
            }
        },
        {
            event: 'event-modal-error', 
            callback: (payload) => {
                useErrorEvent({payload, setters:eventModalSetters})
            }
        },
        {
            event: 'event-modal-success', 
            callback: (payload) => {
                useSuccessEvent({payload, setters:eventModalSetters})
            }
        },
        {
            event: 'event-modal-confirmation', 
            callback: (payload) => {
                useConfirmationEvent({payload, setters:eventModalSetters})
            }
        },
        {
            event: 'event-modal-open', 
            callback: () => {
                eventModalSetters.openModal()
            }
        },
        {
            event: 'event-modal-close', 
            callback: () => {
                eventModalSetters.closeModal()
            }
        }
    ]

    useEventBus(eventsList)

    const eventModalComponent = (
        <EventModal ref={eventModalRef}>
            <EventModalHeader 
                ref={eventModalHeaderRef} 
                handleClose={eventModalSetters.closeModal}
            />
            <EventModalContent ref={eventModalContentRef} />
            <EventModalFooter ref={eventModalFooterRef} />
        </EventModal>
    )

    return {
        eventModalSetters,
        eventModalComponent
    }
}
