import styled from 'styled-components';

export const MenuStyled = styled.div`
  display: flex;
  flex-flow: nowrap column;
  padding: 0 10px;
  height: 100%;
  justify-content: space-between;
  overflow-y: auto;
`;

export const Header = styled.header`
  h2 {
    font-size: 18px;
    margin: 10px 0;
  }
`;

export const Footer = styled.footer`
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0 0;

  h5,
  p {
    margin: 3px 0;
  }
  p {
    font-size: 12px;
  }

  svg {
    cursor: pointer;
  }
`;

export const MenuUl = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-flow: nowrap column;
  gap: 5px;
  height: 100%;

  li {
    position: relative;
    padding: 10px 3px;
    border-radius: 10px;
    transition: background ease 300ms;
    cursor: pointer;

    &:hover {
      background: #f9fafb;
    }
    p {
      font-weight: 400;
      font-size: 16px;
      padding-left: 45px;
      margin: 0;

      ::before {
        content: '';
        width: 25px;
        height: 25px;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(50%, -50%);
        background: #d9d9d9;
        border-radius: 100vw;
      }
    }
  }
`;
