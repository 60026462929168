import React from 'react';
import { PieChart_ } from './instances.js';
import PieChartCircle from './components/PieChartCircle.index.jsx';
import { getSize } from './getSize.js';
import { Container, PieChartStyled } from './PieChart.style';

export default function PieChart({ data, svg = {}, container, showLegend }) {
  const DATA_LENGTH_IS_ABOVE_SIX = data.length > 6;

  const pieChart = new PieChart_({
    data,
    showLegend,
    DATA_LENGTH_IS_ABOVE_SIX
  });

  const renderBody = () => {
    const { data_ } = pieChart.chart;
    const { largestFirstGroupText, largestLastGroupText } = pieChart.legend;

    const elements = [...data_.values()].map(
      ({ key, percentage, distance, color }) => (
        <PieChartCircle
          key={key}
          circumference={pieChart.circumference}
          strokeWidth={pieChart.strokeWidth}
          {...getSize(
            'circles',
            showLegend ? 'dynamic' : 'noLegend'
          )({
            color,
            percentage,
            data,
            DATA_LENGTH_IS_ABOVE_SIX,
            largestFirstGroupText,
            largestLastGroupText
          })}
          {...(distance ? { dashOffset: distance } : {})}
        />
      )
    );

    return elements;
  };
  const renderLegend = () => {
    const renderCircles = () => {
      if (DATA_LENGTH_IS_ABOVE_SIX) {
        const {
          firstGroup,
          lastGroup,
          largestFirstGroupText,
          largestLastGroupText
        } = pieChart.legend;

        let xPosition;

        if (
          largestFirstGroupText.length > 10 &&
          largestLastGroupText.length > 10
        ) {
          xPosition = {
            circle: '40%',
            text: '43%'
          };
        } else if (
          largestFirstGroupText.length > 10 ||
          largestLastGroupText.length > 10
        ) {
          xPosition = {
            circle: '50%',
            text: '53%'
          };
        } else {
          xPosition = {
            circle: '57%',
            text: '60%'
          };
        }

        const firstGroupElements = firstGroup.map(
          ({ key, value, color, legend }, index) => {
            return (
              <g key={key}>
                <circle
                  {...getSize(
                    'legendCircle',
                    'dynamic'
                  )({
                    index,
                    color,
                    radius: '1.5%',
                    cx: xPosition.circle
                  })}
                />
                <text
                  {...getSize(
                    'legendText',
                    'dynamic'
                  )({
                    index,
                    increment: 0.1,
                    x: xPosition.text,
                    fontSize: '3.8'
                  })}
                >
                  {legend}
                </text>
                <text
                  {...getSize(
                    'legendText',
                    'dynamic'
                  )({
                    index,
                    increment: 0.55,
                    x: xPosition.text,
                    fontSize: '3.8'
                  })}
                >
                  » {value}
                </text>
              </g>
            );
          }
        );

        const lastGroupElements = lastGroup.map(
          ({ key, value, color, legend }, index) => {
            return (
              <g key={key}>
                <circle
                  {...getSize(
                    'legendCircle',
                    'dynamic'
                  )({
                    index,
                    color,
                    radius: '1.5%',
                    cx: xPosition.circle
                  })}
                />
                <text
                  {...getSize(
                    'legendText',
                    'dynamic'
                  )({
                    index,
                    increment: 0.1,
                    x: xPosition.text,
                    fontSize: '3.8'
                  })}
                >
                  {legend}
                </text>
                <text
                  {...getSize(
                    'legendText',
                    'dynamic'
                  )({
                    index,
                    increment: 0.55,
                    x: xPosition.text,
                    fontSize: '3.8'
                  })}
                >
                  » {value}
                </text>
              </g>
            );
          }
        );

        return (
          <>
            <g>{firstGroupElements}</g>
            <g
              transform={`translate(${
                largestFirstGroupText.length * 2 + 12
              }, 0)`}
            >
              {lastGroupElements}
            </g>
          </>
        );
      } else {
        return data.map(({ key, value, color, legend }, index) => {
          return (
            <g key={key}>
              <circle
                {...getSize(
                  'legendCircle',
                  'dynamic'
                )({ index, color, radius: '1.5%', cx: '60%' })}
              />
              <text
                {...getSize(
                  'legendText',
                  'dynamic'
                )({ index, increment: 0.1, x: '63%', fontSize: '3.8' })}
              >
                {legend}
              </text>
              <text
                {...getSize(
                  'legendText',
                  'dynamic'
                )({ index, increment: 0.55, x: '63%', fontSize: '3.8' })}
              >
                » {value}
              </text>
            </g>
          );
        });
      }
    };

    const circles = renderCircles();
    return <g transform='translate(0 10)'>{circles}</g>;
  };
  const renderChart = () => {
    return (
      <>
        <g>{renderBody()}</g>
        {showLegend && renderLegend()}
      </>
    );
  };

  const calcSize = () => {
    if (data.length > 6) {
      const { width, height, viewPort } = getSize('wrapper', 'dynamic')(data);
      return { width, height, viewPort };
    } else {
      const { width, height, viewPort } = getSize(
        'wrapper',
        showLegend ? 'withLegend' : 'noLegend'
      );
      return { width, height, viewPort };
    }
  };

  const { width, height, viewPort } = calcSize();

  return (
    <Container size={container ?? 'auto'} legend={showLegend}>
      <PieChartStyled
        {...svg}
        width={
          showLegend
            ? svg.width || width
            : svg.width || getSize('wrapper', 'noLegend').width
        }
        height={
          showLegend
            ? svg.height || height
            : svg.height || getSize('wrapper', 'noLegend').height
        }
        viewBox={viewPort}
      >
        {renderChart()}
      </PieChartStyled>
    </Container>
  );
}
