import React from 'react';

import Menu from '../../../components/Menu/Menu.index';
import {
  handleOptionsView,
  optionsViewlist,
  userDetails
} from '../../hooks/useSidebarConfig';
import { checkObjectKeys } from '../checkObjectKeys';

const setOptionsView = key => {
  if (key === null) {
    handleOptionsView(<></>);
    return;
  }

  const optionView = optionsViewlist.get(key);

  if (optionView?.list) {
    let menuJSX;

    if (!(optionView.list instanceof Array)) {
      const areThereKeys = checkObjectKeys(optionView.list);

      if (areThereKeys) {
        menuJSX = (
          <Menu
            optionView={optionView}
            mappedList={Object.entries(optionView.list)}
            user={userDetails}
          />
        );
      } else {
        menuJSX = (
          <Menu optionView={optionView} mappedList={[]} user={userDetails} />
        );
      }
      handleOptionsView(menuJSX);
      return;
    }

    menuJSX = (
      <Menu
        optionView={optionView}
        mappedList={optionView.list}
        user={userDetails}
      />
    );

    handleOptionsView(menuJSX);
    return;
  }

  handleOptionsView(
    <Menu optionView={optionView} mappedList={[]} user={userDetails} />
  );
};

export default setOptionsView;
