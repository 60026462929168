import { useEffect } from 'react'
import PropTypes from 'prop-types'

export function useEventBus(eventsList = []) {
    useEffect(() => {
        eventsList.forEach(item => {
            window.eventBus.on(item.event, item.callback) 
        })

        return () => {
            eventsList.forEach(item => {
                window.eventBus.remove(item.event)
            })
        }
    }, [])
}

useEventBus.propTypes = {
    eventsList: PropTypes.arrayOf(
        PropTypes.shape({
            event: PropTypes.string,
            callback: PropTypes.func
        })
    ).isRequired
}