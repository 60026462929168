import React from "react"
import Button from '../../../../../../../../components/Button/Button.index.jsx'
import { useIcon } from "../../../../../../../../components/Icon/logic/hooks/useIcon"
import { 
    ConfirmationTitle, 
    ConfirmationMessage, 
    FooterButtonsWrapper 
} from "./Confirmation.style"

export default function useConfirmationEvent ({payload, setters}) {
    setters.setConfigs(payload?.configs)

    const header = []
    const content = []
    const footer = []

    mountHeader(header, payload)
    mountContent(content, payload)
    mountFooter(footer, payload, setters.closeModal)
    setters.setHeader(header)
    setters.setContent(content)
    setters.setFooter(footer)
    setters.openModal()
}

function getDeleteTitle () {
    return <ConfirmationTitle>Tem certeza?</ConfirmationTitle>
}
function mountHeader (header, payload) {
    const headerBuffer = []
    if(payload.type === 'delete') {
        headerBuffer.push(getDeleteTitle())
    }
    header.push(...headerBuffer)
}

function getDeleteMessage (payload) {
    let message = <ConfirmationMessage></ConfirmationMessage>
    if (payload?.customMessage) {
        message = <ConfirmationMessage>{payload.customMessage}</ConfirmationMessage>
        return message
    }
    const { item } = {...payload}
    if(item) {
        let stringMessage = "Tem certeza que deseja deletar "
        if (item?.group && item?.gender) {
            stringMessage += (item.gender === 'F' ? 'a ' : 'o ')
            stringMessage += item.group

            if(item?.name) {
                message = <ConfirmationMessage>
                    {stringMessage}
                    <strong> {item.name}</strong>?
                    Essa ação não pode ser desfeita.
                </ConfirmationMessage>
            } else {
                message = <ConfirmationMessage>
                    {stringMessage}?
                    Essa ação não pode ser desfeita.
                </ConfirmationMessage>
            }
        } 
        return message
    }
}

function mountContent (content, payload) {
    const contentBuffer = []
    if(payload.type === 'delete') {
        contentBuffer.push(getDeleteMessage(payload))
    }
    content.push(...contentBuffer)
}

function getDeleteFooter (payload, closeModal) {
    const { callBack, item } = {...payload}
    return <FooterButtonsWrapper>
        <ButtonDelete itemGroup={item?.group} callBack={callBack} closeModal={closeModal}/>
        <ButtonCancel callBack={callBack} closeModal={closeModal}/>
    </FooterButtonsWrapper>
}

function mountFooter (footer, payload, closeModal) {
    const footerBuffer = []
    switch (payload.type) {
        case 'delete':
            footerBuffer.push(getDeleteFooter(payload, closeModal))
            break
        default:
            break
    }

    footer.push(...footerBuffer)
}

function ButtonDelete ({itemGroup, callBack, closeModal}) {
    const [DeleteIcon] = useIcon('delete')
    let btnTitle = 'Deletar'
    
    if(itemGroup) {
        btnTitle += ' ' + itemGroup
    }

    function handleClick () {
       callBack({ response: true })
       closeModal()
    }
    
    return <Button 
        // Icon={DeleteIcon}  
        style={{
            backgroundColor: 'hsl(0, 100%, 63%)',
            color: 'hsl(0, 100%, 100%)'
        }}
        onClick={handleClick}
    >
        <DeleteIcon />
        <span style={{fontWeight: 'bold'}}>{btnTitle}</span>
    </Button>
}

function ButtonCancel ({callBack, closeModal}) {
    function handleClick () {
       callBack({ response: false })
       closeModal()
    }

    return <Button title={'Cancelar'} onClick={handleClick}/>
}