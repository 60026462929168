import React from 'react';
import PieChart from '../../../Charts/PieChart/PieChart.index.jsx';
import Wrapper from '../../../Charts/PieChart/components/Wrapper/Wrapper.index.jsx';

export const CHARTS = {
  PieChart: function PieChart_({ wrapperProps, chartProps }) {
    return (
      <Wrapper {...wrapperProps}>
        <PieChart {...chartProps} />
      </Wrapper>
    );
  }
};
