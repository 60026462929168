import React, { useState, useEffect } from 'react';
import { ICONS } from './components/Icons';
import ParentIcon from './Icon.index.jsx';

const createIcon = () => {
  let icon = {
    iconJsx: <></>,
    parentJsx: <></>,
    name: '',
    props: {},
    setters: []
  };

  const generate = ({ properties }) => {
    let handleIcon = () => false;
    let setProperties_ = () => false;

    function Icon(props) {
      const [_, setProperties] = useState(props);

      updateIcon(props);
      setProperties_ = setProperties;

      useEffect(() => {
        icon.setters = [...icon.setters, setProperties];
      }, []);

      return <ParentIcon icon={getIcon()} />;
    }

    handleIcon = props => {
      let incomingProps = props;

      if (props instanceof Function) {
        const mergedProps = props(properties.props);
        incomingProps = mergedProps;

        updateIcon(mergedProps);
      } else {
        updateIcon(props);
      }

      if (icon.setters.length > 1) {
        icon.setters.forEach(set =>
          set(curr => ({ ...curr, ...incomingProps }))
        );
      } else {
        setProperties_(curr => ({ ...curr, ...incomingProps }));
      }
    };

    icon.props = { ...properties.props };
    // icon.iconJsx = ICONS[properties.name]({ ...properties.props });
    icon.name = properties.name;
    icon.parentJsx = Icon;

    return handleIcon;
  };

  const getIcon = () => {
    const { iconJsx } = icon;

    return iconJsx;
  };

  const updateIcon = incomingProps => {
    const { name, props } = icon;

    icon.props = { ...props, ...incomingProps };
    icon.iconJsx = ICONS[name](icon.props);
  };

  return {
    generate,
    updateIcon,
    icon
  };
};

export { createIcon };
