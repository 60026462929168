import React from "react"
export default function Add ({ svg, path, ...rest }) {
    return <svg
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...svg}
      {...rest}
    >
      <path
        d='M12.5 22C18.0228 22 22.5 17.5228 22.5 12C22.5 6.47715 18.0228 2 12.5 2C6.97715 2 2.5 6.47715 2.5 12C2.5 17.5228 6.97715 22 12.5 22Z'
        stroke='#344054'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
        {...path}
      />
      <path
        d='M12.5 8V16'
        stroke='#344054'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
        {...path}
      />
      <path
        d='M8.5 12H16.5'
        stroke='#344054'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
        {...path}
      />
    </svg>
}