import styled from 'styled-components';

export const InputRadioWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;

    & > input[type=radio] {
        width: 16px;
        height: 16px;
        position: relative;
        margin-top:-2px;
        margin-left:-2px;

        &::after {
            content:'';
            position:absolute;
            width: 18px;
            height: 18px;
            top:-1px;
            left:-1px;
            background-color: hsl(0,0%,100%);
            border: 1px solid hsl(210, 4%, 82%);
            border-radius:50%
        }

        &:checked::before {
            content:'';
            position:absolute;
            width: 13px;
            height: 13px;
            top:calc(50% + 1px);
            left:calc(50% + 1px);
            transform: translate(-50%,-50%);

            background-color: hsl(210, 4%, 82%);
            border-radius:50%;
            z-index:2;
        }
    }
`