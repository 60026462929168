import React from "react"
import Button from '../../../../../../../../components/Button/Button.index.jsx'
import { 
    ErrorHeaderTitle,
    ErrorsContainer,
    ErrorsTitle,
    ErrorsList,
    ErrorListItem,
    ErrorFooter
} from "./Error.style"

export default function useErrorEvent ({payload, setters}) {
    setters.setConfigs(payload?.configs)

    const header = []
    const content = []
    const footer = []

    mountHeader(header, payload)
    mountContent(content, payload)
    mountFooter(footer, setters.closeModal)

    setters.setHeader(header)
    setters.setContent(content)
    setters.setFooter(footer)
    setters.openModal()
}

function mountHeader (header, payload) {
    const {title} = {...payload}
    header.push(<ErrorHeaderTitle>{title}</ErrorHeaderTitle>)
}

function mountContent (content, payload) {
    const contentBuffer = []
    const {errors} = {...payload}
    if(Array.isArray(errors)) {
        if(errors?.length > 0) {
            contentBuffer.push(
                <ErrorsContainer>
                    <ErrorsTitle>{errors.length > 1 ? 'Erros:' : 'Erro:'}</ErrorsTitle>
                    <ErrorsList>
                        {errors.map((message, key) => {
                            const errorMessage = Object.values(message)[0]
                            return <ErrorListItem key={key}>{errorMessage}</ErrorListItem>
                        })}
                    </ErrorsList>
                </ErrorsContainer>
            )
        } else {
            contentBuffer.push(
                <ErrorsContainer>
                    <ErrorsTitle>Erro:</ErrorsTitle>
                    <ErrorsList>
                        <ErrorListItem>Erro</ErrorListItem>
                    </ErrorsList>
                </ErrorsContainer>
            )
        }
    }
    content.push(...contentBuffer)
}

function mountFooter (footer, closeModal) {
    footer.push(<ErrorFooter><Button title={'OK'} onClick={closeModal}/></ErrorFooter>)
}

