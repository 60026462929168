import styled from 'styled-components';

export const StyledWrapper = styled.article`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
`;

export const Header = styled.header`
  flex: 0.1 1 0;
  padding: 20px;
  h2 {
    font-size: 18px;
    margin: 0;
    font-weight: 500;
  }
`;

export const Body = styled.section`
  width: 100%;
  height: calc(100% - 138px);
  flex: 0.8 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Footer = styled.footer`
  flex: 0.1 1 0;
  padding: 15px 20px;
  border-top: 1px solid #eaecf0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
  padding-left: auto;

  button {
    font-size: 14px;
  }
`;
