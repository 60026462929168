/* eslint-disable no-unused-vars */
import React, { forwardRef } from 'react';
import { Item, StyledMenu } from '../Select.style';

function Menu({ menuStyle, extractKey, items, selected, handleSelect }, ref) {
  const _items = items.map(item => (
    <Item
      key={extractKey?.(item) ?? item.value}
      selected={item.value === selected}
      disabled={item.disabled}
      {...(!item.disabled && {
        onClick: () => handleSelect(item.value),
        onMouseDown: e => e.preventDefault()
      })}
    >
      {item.label}
    </Item>
  ));

  return (
    <StyledMenu ref={ref} style={menuStyle} data-hide='true'>
      {_items}
    </StyledMenu>
  );
}

const Menu_ = forwardRef(Menu);
export default Menu_;
