import React from 'react';
import { StyledWrapper, Header, Body, Footer } from './Wrapper.style';
import Button from '../../../../Button/Button.index.jsx';
import { getIcon } from '../../../../Icon/getIcon.js';

export default function Wrapper({ title, onSeeAllClick, filter, children }) {
  return (
    <StyledWrapper>
      <Header>
        <h2>{title}</h2>
      </Header>
      <Body>{children}</Body>
      <Footer>
        {filter && (
          <Button
            noStyle
            size='auto'
            title='Filtrar'
            Icon={getIcon('filter')}
            onClick={filter.onClick}
          />
        )}
        <Button size='auto' title='Ver tudo' onClick={onSeeAllClick} />
      </Footer>
    </StyledWrapper>
  );
}
