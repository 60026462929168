import React, { forwardRef, useLayoutEffect, useRef, useState } from 'react';
import { ChevronWrapper, Wrapper } from './Select.style';
import Input from './components/Input';
import Menu from './components/Menu';

import { getIcon } from '../Icon/getIcon';
import { HiddenInput } from '../Inputs/Input/Input.style';

const Chevron = getIcon('chevron');

function Select(
  {
    name,
    inputStyle = {},
    containerStyle = null,
    menuStyle = {},
    defaultValue,
    inputProps = {},
    comparison,
    options = [],
    onChange,
    error = {},
    extractKey
  },
  ref
) {
  const _ref = useRef();
  const control = useRef({
    useChevron: false,
    inputIsFocused: false
  });
  let inputRef = ref && Object.keys(ref)[0] === 'current' ? ref : _ref;

  const hiddenInputRef = useRef();
  const menuRef = useRef();
  const wrapperRef = useRef();
  const chevronRef = useRef();
  const nativeSetter = useRef();

  const [selected, setSelected] = useState(null);

  const handleSelect = value => {
    const selectedOption = options.find(option => option.value === value);

    nativeSetter.current(updateInputValue(selectedOption.label, false));

    inputRef.current.blur();
    menuRef.current.dataset.hide = 'true';
    hiddenInputRef.current.value = value;

    if (onChange instanceof Function) {
      onChange(selectedOption.value, selectedOption);
    }

    setSelected(options.find(o => o.label === selectedOption.label).value);
  };

  const handleMenuOnChevronClick = () => {
    if (control.current.useChevron) {
      inputRef.current.focus();
      focusIn();
    } else {
      focusOut();
    }
  };

  const focusIn = () => {
    menuRef.current.dataset.hide = 'false';
    chevronRef.current.dataset.up = 'true';
  };

  const focusOut = () => {
    menuRef.current.dataset.hide = 'true';
    chevronRef.current.dataset.up = 'false';
  };

  const updateInputValue = (inputValue, needFiltering) => {
    if (!needFiltering) {
      return inputValue;
    }

    const itemResult =
      options.find(option =>
        comparison
          ? comparison(option, inputValue)
          : String(option.value) === String(inputValue)
      ) ??
      options.find(option =>
        comparison
          ? comparison(option, inputValue)
          : String(option.label) === String(inputValue)
      );

    inputRef.current.blur();

    if (itemResult?.label) {
      setSelected(options.find(o => o.label === itemResult.label).value);
    }

    return itemResult?.label ?? '';
  };

  useLayoutEffect(() => {
    inputRef.current.addEventListener('focusin', focusIn);
    inputRef.current.addEventListener('focusout', focusOut);

    const nativeValueSetter = Object.getOwnPropertyDescriptor(
      window.HTMLInputElement.prototype,
      'value'
    ).set;

    nativeSetter.current = nativeValueSetter.bind(inputRef.current);

    Object.defineProperty(inputRef.current, 'value', {
      set: function (newValue) {
        nativeValueSetter.call(this, updateInputValue(newValue, true));
      }
    });

    let _value = defaultValue || inputRef.current.value;

    if (_value) {
      nativeSetter.current(updateInputValue(_value, true));
    }

    return () => {
      inputRef.current.removeEventListener('focusin', focusIn);
      inputRef.current.removeEventListener('focusout', focusOut);
    };
  }, []);

  const handleClickOutside = event => {
    if (event.target.id === 'chevron') {
      if (menuRef.current.dataset.hide === 'true') {
        control.current.useChevron = true;
      } else {
        control.current.useChevron = false;
      }
    } else {
      control.current.useChevron = false;
    }
  };

  useLayoutEffect(() => {
    wrapperRef.current.addEventListener('mousedown', handleClickOutside);
    return () => {
      wrapperRef.current.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Wrapper
      ref={wrapperRef}
      {...(containerStyle && { style: containerStyle })}
    >
      <HiddenInput
        ref={hiddenInputRef}
        name={name}
        defaultValue={defaultValue}
      />
      <Input
        ref={inputRef}
        {...inputProps}
        name={name}
        error={error.message}
        updateInputValue={updateInputValue}
        style={{ ...inputStyle, ...inputProps.style }}
        autoComplete='off'
        Chevron={
          <ChevronWrapper
            id='chevron'
            type='button'
            ref={chevronRef}
            onClick={handleMenuOnChevronClick}
          >
            <Chevron />
          </ChevronWrapper>
        }
        readOnly
      />
      <Menu
        ref={menuRef}
        style={menuStyle}
        extractKey={extractKey}
        handleSelect={handleSelect}
        selected={selected}
        items={options}
      />
    </Wrapper>
  );
}

export default forwardRef(Select);
