import React, { useRef } from 'react'
import { useEventBus } from '../../../../services/EventBus/hooks/useEventBus'
import EventLoader from '../../EventLoader.index.jsx'

export default function useEventModal() {
    const eventLoaderRef = useRef()

    const eventLoaderSetters = {
        openLoader: () => {
            if(eventLoaderRef.current?.style) {
                eventLoaderRef.current.style.display = 'grid'
            }
        },
        closeLoader: () => {
            if(eventLoaderRef.current?.style) {
                eventLoaderRef.current.style.display = 'none'
            }
        }
    }

    const eventsList = [
        {
            event: 'event-loader-open', 
            callback: () => eventLoaderSetters.openLoader()
        },
        {
            event: 'event-loader-close', 
            callback: () => eventLoaderSetters.closeLoader()
        }
    ]

    useEventBus(eventsList)

    const eventLoaderComponent = (
        <EventLoader ref={eventLoaderRef} />
    )

    return {
        eventLoaderSetters,
        eventLoaderComponent
    }
}
