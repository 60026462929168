import styled from 'styled-components';

export const MainContainer = styled.div`
  position: relative;
  * {
    box-sizing: border-box;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  label,
  li,
  a,
  strong,
  span {
    font-family: 'Poppins', sans-serif;
  }
`;

export const MainView = styled.div`
  background: #09253a;
  width: 75px;
  max-width: 75px;
  min-width: 75px;
  display: flex;
  flex-flow: nowrap column;
  height: 100vh;
  padding: 10px 8px;
  z-index: 11;
  position: relative;
  overflow-y: auto;

  ::-webkit-scrollbar {
    display: none;
  }
`;
export const OptionsView = styled.div`
  background: #fff;
  width: 300px;
  transform: ${({ visible }) =>
    visible ? 'translateX(75px)' : 'translateX(-100%)'};
  /* visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')}; */
  /* opacity: ${({ visible }) => (visible ? '1' : '0')}; */
  height: 100vh;
  padding: 10px;
  position: absolute;
  top: 0;
  z-index: 10;
  transition: transform cubic-bezier(0.79, 0.14, 0.15, 0.86) 300ms;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
`;

export const Logo = styled.a`
  align-self: center;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-flow: nowrap column;
  justify-content: space-between;
  height: 100%;
  position: relative;

  > div {
    display: flex;
    flex-flow: nowrap column;
    margin: 15px 0 0;
    gap: 10px 0;
    align-items: center;
  }
`;
