/**
 * Applies a custom treatment to a string.
 * @param {string} string String you wanna apply a treatment to
 * @param { { capitalizeFirstLetter: string, capitalizeWords: string, toKebabCase: string, textLimiter: string} } action - Treatment action
 * @param {object} options Extra Options for some actions.
 * @returns {string} Treated String
 */
 function treatString(string, action, options = {}) {
    switch (action) {
      case 'capitalizeFirstLetter':
        string = string.charAt(0).toUpperCase() + string.slice(1)
        break
      case 'capitalizeWords':
        string = string.replace(/(?:^|\s)\S/g, function (a) {
          return a.toUpperCase()
        })
        break
      case 'toKebabCase':
        string = string.replaceAll(/[A-Z]/g, '-$&').toLowerCase()
        break
      case 'textLimiter':
        if (typeof options?.limit !== 'undefined') {
          string =
            string.length < options.limit
              ? string
              : string.slice(0, options.limit) + '...'
        }
        break
    }
    return string
  }
  
  const stringHelpers = {
    treatString
  }
  
  export default stringHelpers